/* USER */

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const SESSION_CHECK = "SESSION_CHECK";
export const VERIFY_REQUEST = "VERIFY_REQUEST";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";

export const ADD_USER = 'ADD_USER';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILED = 'ADD_USER_FAILED';
export const UPDATE_USERS_COMPANY_NAME = 'UPDATE_USERS_COMPANY_NAME';


/* Roles*/
export const GET_ROLES = 'GET_ROLES';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_FAILED = 'GET_ROLES_FAILED';

export const GET_USERS_BY_COMPANY_ID = 'GET_USERS_BY_COMPANY_ID';
export const GET_USERS_BY_COMPANY_ID_SUCCESS = 'GET_USERS_BY_COMPANY_ID_SUCCESS';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const DELETE_USER = 'DELETE_USER';





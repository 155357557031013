// @flow
import { all, call, put, fork, takeEvery } from 'redux-saga/effects';

import { INIT_MENU, CHANGE_ACTIVE_MENU_FROM_LOCATION } from './constants';
import { authProtectedRoutes as routes } from '../../routes';
import assignIds from './utils';
import { initMenuSuccess, changeActiveMenuFromLocationSuccess } from './actions';
import React from 'react';

/**
 * Activate menu items from location
 * @param {*} menuItems
 */
const getActivatedMenuItemIds = menuItems => {
    var matchingItems = [];
    for (var menuItem of menuItems) {
        if (window.location.pathname.indexOf(menuItem.path) === 0) {
            matchingItems.push(menuItem.id);
        }

        if (typeof menuItem.children !== 'undefined') {
            matchingItems = [...matchingItems, ...getActivatedMenuItemIds(menuItem.children)];
        }
    }
    return matchingItems;
};

/**
 * Initilizes the menu
 */
function* initMenuAndItems(action) {
    try {
        const userRoles = action.payload.userRoles;
        const authorizedRoutes = [];
        for (let i = 0; i < routes.length; i++) {
            if(!routes[i].roles || routes[i].roles.length == 0 ||  (routes[i].roles.some( r => userRoles.map(function(ur) { return ur.name; }).indexOf(r) >= 0)) )
            {
                authorizedRoutes.push(routes[i]);
            }
        }
        const menuItems = assignIds(authorizedRoutes);

        yield put(initMenuSuccess(menuItems));
    } catch (error) {console.log(error)}
}

/**
 * change the active menu item based on location
 */
function* changeActiveMenuFromLocation() {
    try {
        const menuItems = assignIds(routes);
        const activatedMenuItemIds = yield call(getActivatedMenuItemIds, menuItems);
        yield put(changeActiveMenuFromLocationSuccess(activatedMenuItemIds));
    } catch (error) {}
}

/**
 * Watchers
 */
export function* watchMenuInit() {
    yield takeEvery(INIT_MENU, initMenuAndItems);
}

export function* watchMenuChange() {
    yield takeEvery(CHANGE_ACTIVE_MENU_FROM_LOCATION, changeActiveMenuFromLocation);
}

function* appMenuSaga() {
    yield all([fork(watchMenuInit), fork(watchMenuChange)]);
}

export default appMenuSaga;

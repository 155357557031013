/* Gears */
export const GET_GEARS = 'GET_GEARS';
export const GET_GEARS_SUCCESS = 'GET_GEARS_SUCCESS';
export const GET_GEARS_FAILED = 'GET_GEARS_FAILED';

/* Industries */
export const GET_INDUSTRIES = 'GET_INDUSTRIES';
export const GET_INDUSTRIES_SUCCESS = 'GET_INDUSTRIES_SUCCESS';
export const GET_INDUSTRIES_FAILED = 'GET_INDUSTRIES_FAILED';
 
/* Apps */
export const GET_APPS = 'GET_APPS';
export const GET_APPS_SUCCESS = 'GET_APPS_SUCCESS';
export const GET_APPS_FAILED = 'GET_APPS_FAILED';
export const GET_APP = 'GET_APP';
export const GET_APP_SUCCESS = 'GET_APP_SUCCESS';
export const GET_APP_FAILED = 'GET_APP_FAILED';
export const ADD_APP = 'ADD_APP';
export const ADD_APP_SUCCESS = 'ADD_APP_SUCCESS';
export const ADD_APP_FAILED = 'ADD_APP_FAILED';
export const UPDATE_APP = 'UPDATE_APP';
export const UPDATE_APP_SUCCESS = 'UPDATE_APP_SUCCESS';
export const UPDATE_APP_FAILED = 'UPDATE_APP_FAILED';
export const DELETE_APP = 'DELETE_APP';
export const DELETE_APP_SUCCESS = 'DELETE_APP_SUCCESS';
export const DELETE_APP_FAILED = 'DELETE_APP_FAILED';

/* Companies */
export const ADD_COMPANY = "ADD_COMPANY";
export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS";
export const ADD_COMPANY_FAILED = "ADD_COMPANY_FAILED";
export const GET_COMPANIES = "GET_COMPANIES";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
export const GET_COMPANIES_FAILED = "GET_COMPANIES_FAILED";
export const GET_COMPANY = "GET_COMPANY";
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS";
export const GET_COMPANY_FAILED = "GET_COMPANY_FAILED";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_FAILED = "UPDATE_COMPANY_FAILED";
export const DEACTIVATE_COMPANY = "DEACTIVATE_COMPANY";


/* Notifications */
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

/* App */

export const CLEAR_APP = 'CLEAR_APP';

/* Modules */
export const GET_MODULES = 'GET_MODULES';
export const GET_MODULES_SUCCESS = 'GET_MODULES_SUCCESS';
export const GET_MODULES_FAILED = 'GET_MODULES_FAILED';
export const GET_MODULES_BY_APP_ID = 'GET_MODULES_BY_APP_ID';

export const VERIFY_COMPANY_STATUS = 'VERIFY_COMPANY_STATUS';
export const VERIFY_COMPANY_STATUS_RESULT = 'VERIFY_COMPANY_STATUS_RESULT';
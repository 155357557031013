// @flow
import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE,
    SESSION_CHECK,
    VERIFY_REQUEST,
    VERIFY_SUCCESS,
    ADD_USER,
    ADD_USER_SUCCESS,
    ADD_USER_FAILED,
    GET_ROLES,
    GET_ROLES_SUCCESS,
    GET_ROLES_FAILED,
    GET_USERS_BY_COMPANY_ID,
    GET_USERS_BY_COMPANY_ID_SUCCESS,
    UPDATE_USER,
    UPDATE_USER_SUCCESS,
    DELETE_USER,
    UPDATE_USERS_COMPANY_NAME,
} from './constants';

export const loginRequest = (from) => ({
    type: LOGIN_REQUEST,
    payload: from,
});


export const loginSuccess = (user) => ({
    type: LOGIN_SUCCESS,
    payload: user,
});

export const loginFailure = () => ({
    type: LOGIN_FAILURE,
    payload: {},
});

export const logoutRequest = () => ({
    type: LOGOUT_REQUEST,
    payload: {},
});

export const logoutSuccess = () => ({
    type: LOGOUT_SUCCESS,
    payload: {},
});

export const logoutFailure = () => ({
    type: LOGOUT_FAILURE,
    payload: {},
});

export const verifyRequest = () => ({
    type: VERIFY_REQUEST,
    payload: {},
});
export const sessionCheck = () => ({
    type: SESSION_CHECK,
    payload: {},
});

export const verifySuccess = () => ({
    type: VERIFY_SUCCESS,
    payload: {},
});


export const addUser = (user) => ({
    type: ADD_USER,
    payload: user,
});

export const addUserSuccess = (message) => ({
    type: ADD_USER_SUCCESS,
    payload: message,
});

export const addUserFailed = (error) => ({
    type: ADD_USER_FAILED,
    payload: error,
});

/* ROLES*/
export const getRoles = () => ({
    type: GET_ROLES,
    payload: {},
});

export const getRolesSuccess = (roles) => ({
    type: GET_ROLES_SUCCESS,
    payload: roles,
});

export const getRolesFailed = (error) => ({
    type: GET_ROLES_FAILED,
    payload: error,
});

export const getUsersByCompanyId = (companyId) => ({
    type: GET_USERS_BY_COMPANY_ID,
    payload: companyId,
});

export const getUsersByCompanyIdSuccess = (companies) => ({
    type: GET_USERS_BY_COMPANY_ID_SUCCESS,
    payload: companies,
});

export const updateUser = (user) => ({
    type: UPDATE_USER,
    payload: user,
});

export const updateUsersCompanyName = (company) => ({
    type: UPDATE_USERS_COMPANY_NAME,
    payload: company,
});



export const updateUserSuccess = (message) => ({
    type: UPDATE_USER_SUCCESS,
    payload: message,
});

export const deleteUser = (user) => ({
    type: DELETE_USER,
    payload: user,
});




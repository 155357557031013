import React from 'react';
import { Switch } from 'react-router-dom';
import { connect } from 'react-redux';

// layout HOC
import withLayout from '../components/Layout';
import { allFlattenRoutes as routes } from './index';


const Routes = (props) => {
        const { isAuthenticated, isVerifying, userRoles,isCompanyStatusActive} = props;
        // rendering the router with layout
        return (
            <Switch>

                    {routes.map((route, index) => {

                            return (
                                !route.children && (
                                    <route.route
                                        key={index}
                                        path={route.path}
                                        roles={route.roles}
                                        exact={route.exact}
                                        isAuthenticated={isAuthenticated}
                                        isVerifying={isVerifying}
                                        isCompanyStatusActive={isCompanyStatusActive}
                                        userRoles={userRoles}
                                        component={withLayout(props => {
                                                return <route.component {...props} />;
                                        })}></route.route>
                                )
                            );
                    })}
            </Switch>
        );
}
function mapStateToProps(state) {
        return {
                isAuthenticated: state.User.isAuthenticated,
                isVerifying: state.Admin.isVerifying,
                userRoles: state.User.user.roles,
                isCompanyStatusActive: state.Admin.isCompanyStatusActive
        };
}

export default connect(mapStateToProps)(Routes);

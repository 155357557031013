import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { getActionOnAPI, postActionOnAPI, putActionOnAPI, deleteActionOnAPI } from '../../helpers/backend';
import {
    ADD_TO_CART,
    REMOVE_APP_FROM_CART,
    GET_CART,
    REMOVE_MODULE_FROM_CART,
    REMOVE_APPS_FROM_CART,
    UPDATE_CART_ITEM,
    UPDATE_CART,
    GET_CARTS_BY_APP_ID
} from './constants';
import { getCartSuccess, getCartFailed,getCartsByAppIdSuccess } from './actions';
import { isEmptyArray } from 'formik';

const apiUrl = process.env.REACT_APP_AM_API_URL;

function* getCart(action) {
    const response = yield call(getActionOnAPI, `${apiUrl}/carts/${action.payload}`);
    if (response === 'ERROR_OCCURED') {
        yield put(getCartFailed(response));
    } else {
        yield put(getCartSuccess({ cartItems: response.items, cart: response }));
    }
}

function* getCartsByAppId(action) {
    const response = yield call(getActionOnAPI, `${apiUrl}/carts/items?appOrModuleId=${action.payload}`);
    if (response && Array.isArray(response)) {
        yield put(getCartsByAppIdSuccess(response));
    } else {
        yield put(getCartFailed(response));
    }
}

function* addItemToCart(action) {
    const response = yield call(putActionOnAPI, `${apiUrl}/carts/${action.payload.company}/items`, action.payload.item);
    yield put({ type: 'GET_CART', payload: action.payload.company });
}

function* removeAppFromCart(action) {
    const response = yield call(
        deleteActionOnAPI,
        `${apiUrl}/carts/${action.payload.company}/items/${action.payload.id}`
    );
    yield put({ type: 'GET_CART', payload: action.payload.company });
}

function* removeModuleFromCart(action) {
    const response = yield call(
        deleteActionOnAPI,
        `${apiUrl}/carts/${action.payload.company}/items/${action.payload.appId}/modules/${action.payload.moduleId}`
    );
    yield put({ type: 'GET_CART', payload: action.payload.company });
}

function* removeAppsFromCart(action) {
    yield call(deleteActionOnAPI, `${apiUrl}/carts/${action.payload}/items`);
    yield put({ type: 'GET_CART', payload: action.payload });
}

function* updateCartItem(action) {
    yield call(
        putActionOnAPI,
        `${apiUrl}/carts/${action.payload.company}/items/${action.payload.cartItemId}`,
        action.payload.cartItem
    );
    yield put({ type: 'GET_CART', payload: action.payload.company });
}

function* updateCart(action) {
    yield call(putActionOnAPI, `${apiUrl}/carts/${action.payload.company}/`, action.payload.cart);
    yield put({ type: 'GET_CART', payload: action.payload.company });
}

export function* watchGetCart() {
    yield takeEvery(GET_CART, getCart);
}

export function* watchAddItemToCart() {
    yield takeEvery(ADD_TO_CART, addItemToCart);
}

export function* watchRemoveAppFromCart() {
    yield takeEvery(REMOVE_APP_FROM_CART, removeAppFromCart);
}

export function* watchRemoveModuleFromCart() {
    yield takeEvery(REMOVE_MODULE_FROM_CART, removeModuleFromCart);
}

export function* watchRemoveAppsFromCart() {
    yield takeEvery(REMOVE_APPS_FROM_CART, removeAppsFromCart);
}

export function* watchUpdateCartItem() {
    yield takeEvery(UPDATE_CART_ITEM, updateCartItem);
}

export function* watchUpdateCart() {
    yield takeEvery(UPDATE_CART, updateCart);
}

export function* watchGetCartsByAppId() {
    yield takeEvery(GET_CARTS_BY_APP_ID, getCartsByAppId);
}


function* cartSaga() {
    yield all([
        fork(watchAddItemToCart),
        fork(watchRemoveAppFromCart),
        fork(watchRemoveModuleFromCart),
        fork(watchRemoveAppsFromCart),
        fork(watchGetCart),
        fork(watchUpdateCartItem),
        fork(watchUpdateCart),
        fork(watchGetCartsByAppId),
    ]);
}

export default cartSaga;

import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import * as FeatherIcon from 'react-feather';
import Loader from '../components/Loader';

import { isUserAuthenticated } from '../helpers/authUtils';

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const LoginCallback = React.lazy(() => import('../pages/auth/LoginCallback'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));

const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
// dashboard
const Dashboard = React.lazy(() => import('../pages/dashboard'));

//Http Errors
const HTTPError500 = React.lazy(() => import('../pages/other/Error500'));
const HTTPError404 = React.lazy(() => import('../pages/other/Error404'));
const HTTPError401 = React.lazy(() => import('../pages/other/Error401'));

// store
const Store = React.lazy(() => import('../pages/store'));

// cart
const Cart = React.lazy(() => import('../pages/cart'));

// subscriptions
const Subscriptions = React.lazy(() => import('../pages/subscriptions'));

// diagnostic
const Diagnostic = React.lazy(() => import('../pages/diagnostic'));

// diagnostic
const StepListing = React.lazy(() => import('../pages/diagnostic/steplisting'));

//app
const App = React.lazy(() => import('../pages/app'));

//admin
const Admin = React.lazy(() => import('../pages/admin'));

//admin2(temp)
const Admin2 = React.lazy(() => import('../pages/admin2'));

// handle auth and authorization
const PrivateRoute = ({
    component: Component,
    roles,
    userRoles,
    isAuthenticated,
    isVerifying,
    isCompanyStatusActive,
    ...rest
}) => (
    <Route
        {...rest}
        render={(props) => {
            if (isVerifying) {
                return (
                    <div>
                        <Loader />
                    </div>
                );
            } else if (isAuthenticated) {
                if (!isCompanyStatusActive) {
                    return <Redirect to={{ pathname: '/error/403' }} />;
                }
                if (
                    !roles ||
                    roles.length == 0 ||
                    roles.some(
                        (r) =>
                            userRoles
                                .map(function (ur) {
                                    return ur.name;
                                })
                                .indexOf(r) >= 0
                    )
                ) {
                    return <Component {...props} />;
                } else {
                    return <Redirect to={{ pathname: '/' }} />;
                }
            } else {
                window.location.href = process.env.REACT_APP_AUTH_URL;
                return (
                    <div>
                        <Loader />
                    </div>
                );
            }
        }}
    />
);

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to="/store" />,
    route: PrivateRoute,
};

// auth
const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/account/logout',
            name: 'Logout',
            component: Logout,
            route: Route,
        },
        {
            path: '/account/confirm',
            name: 'Confirm',
            component: Confirm,
            route: Route,
        },
        {
            path: '/account/forget-password',
            name: 'Forget Password',
            component: ForgetPassword,
            route: Route,
        },
    ],
};

// store
const storeRoutes = {
    path: '/store',
    name: 'Cienappstore',
    nameFr: 'Cienappstore',
    icon: FeatherIcon.ShoppingCart,
    header: 'Navigation',
    badge: {
        variant: 'success',
        text: '1',
    },
    component: Store,
    roles: [],
    route: PrivateRoute,
};

// cart
const cartRoutes = {
    path: '/cart',
    name: 'Cart',
    nameFr: 'Panier',
    icon: FeatherIcon.ShoppingCart,
    header: 'Navigation',
    badge: {
        variant: 'success',
        text: '1',
    },
    component: Cart,
    roles: [],
    route: PrivateRoute,
};

// subscriptions
const subscriptionsRoutes = {
    path: '/subscriptions',
    name: 'My Account',
    nameFr: 'Mon Compte',
    icon: FeatherIcon.Archive,
    header: 'Navigation',
    badge: {
        variant: 'success',
        text: '1',
    },
    component: Subscriptions,
    roles: [],
    route: PrivateRoute,
};

// diagnostic
const diagnosticRoutes = {
    path: '/diagnostic',
    name: 'My Process',
    nameFr: 'Mes Processus',
    icon: FeatherIcon.Send,
    header: 'Navigation',
    badge: {
        variant: 'success',
        text: '1',
    },
    component: Diagnostic,
    roles: [],
    route: PrivateRoute,
};

// dashboards
const dashboardRoutes = {
    path: '/dashboard',
    name: 'Dashboard',
    nameFr: 'Accueil',
    icon: FeatherIcon.Send,
    header: 'Navigation',
    badge: {
        variant: 'success',
        text: '1',
    },
    component: Dashboard,
    roles: [],
    route: PrivateRoute,
};

// adminRoutes
const adminRoutes = {
    path: '/admin',
    name: 'Admin',
    nameFr: 'Admin',
    icon: FeatherIcon.Lock,
    header: 'Navigation',
    badge: {
        variant: 'success',
        text: '1',
    },
    component: Admin,
    roles: ['Admin'],
    route: PrivateRoute,
};

// adminRoutes2
const admin2Routes = {
    path: '/admin2',
    name: 'Admin2',
    nameFr: 'Admin2',
    icon: FeatherIcon.Lock,
    header: 'Navigation',
    badge: {
        variant: 'success',
        text: '1',
    },
    component: Admin2,
    roles: ['Admin'],
    route: PrivateRoute,
};

// HTTP ERRORS
const HTTPErrorRoutes = {
    path: '/error',
    name: 'Error',
    header: 'Custom',
    children: [
        {
            path: '/error/500',
            name: 'Error 500',
            component: HTTPError500,
            route: Route,
        },
        {
            path: '/error/404',
            name: 'Error 404',
            component: HTTPError404,
            route: Route,
        },
        {
            path: '/error/401',
            name: 'Error 401',
            component: HTTPError401,
            route: Route,
        },
    ],
};

// app
const appRoutes = {
    path: '/app',
    name: 'App',
    component: App,
    route: PrivateRoute,
};

// steplisting
const stepListingRoutes = {
    path: '/steplisting',
    name: 'StepListing',
    component: StepListing,
    route: PrivateRoute,
};

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach((item) => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const allRoutes = [
    rootRoute,
    authRoutes,
    storeRoutes,
    cartRoutes,
    subscriptionsRoutes,
    diagnosticRoutes,
    appRoutes,
    stepListingRoutes,
    adminRoutes,
    admin2Routes,
    HTTPErrorRoutes,
];

// diagnosticRoutes removed for initial release
const authProtectedRoutes = [storeRoutes, diagnosticRoutes, cartRoutes, subscriptionsRoutes, adminRoutes, admin2Routes];
const allFlattenRoutes = flattenRoutes(allRoutes);
export { allRoutes, authProtectedRoutes, allFlattenRoutes };

// @flow
import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE,
    VERIFY_REQUEST,
    VERIFY_SUCCESS,
    ADD_USER_SUCCESS,
    ADD_USER_FAILED,
    GET_ROLES,
    GET_ROLES_FAILED,
    GET_ROLES_SUCCESS,
    GET_USERS_BY_COMPANY_ID_SUCCESS,
    UPDATE_USER_SUCCESS,
} from './constants';

const INIT_STATE = {
    user: {
        displayName: 'Anonymous',
        roles: [],
        company: { name: '' },
    },
    isLoggingIn: false,
    isLoggingOut: false,
    
    loginError: false,
    logoutError: false,
    isAuthenticated: false,
    users: [],
    message: '',
    error: '',
    roles: [],
};

const User = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                isLoggingIn: true,
                loginError: false,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggingIn: false,
                isAuthenticated: true,
                user: action.payload,
            };
        case LOGIN_FAILURE:
            return {
                ...state,
                isLoggingIn: false,
                isAuthenticated: false,
                loginError: true,
            };
        case LOGOUT_REQUEST:
            return {
                ...state,
                isLoggingOut: true,
                logoutError: false,
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                isLoggingOut: false,
                isAuthenticated: false,
                user: {},
            };
        case LOGOUT_FAILURE:
            return {
                ...state,
                isLoggingOut: false,
                logoutError: true,
            };
        case VERIFY_REQUEST:
            return {
                ...state,
                isVerifying: true,
                verifyingError: false,
            };
        case VERIFY_SUCCESS:
            return {
                ...state,
                isVerifying: false,
            };
        case ADD_USER_SUCCESS:
            return { ...state, message: action.payload, loading: false,error:'' };
        case UPDATE_USER_SUCCESS:
            return { ...state, message: action.payload,error:''};
        case ADD_USER_FAILED:
            return { ...state, error: action.payload, loading: false };
        case GET_ROLES:
            return { ...state, loading: true };
        case GET_ROLES_SUCCESS:
            return { ...state, roles: action.payload, loading: false };
        case GET_ROLES_FAILED:
            return { ...state, error: action.payload, loading: false };
        case GET_USERS_BY_COMPANY_ID_SUCCESS:
            return { ...state, users: action.payload };
        default:
            return { ...state };
    }
};

export default User;

import { all, call, fork, put, take, takeEvery, cancelled } from 'redux-saga/effects';

import axios from 'axios';
import { push } from 'connected-react-router';

import {
    ADD_USER,
    LOGIN_REQUEST,
    LOGOUT_REQUEST,
    SESSION_CHECK,
    GET_ROLES,
    GET_USERS_BY_COMPANY_ID,
    UPDATE_USER,
    DELETE_USER,
    UPDATE_USERS_COMPANY_NAME,
} from './constants';
import {
    addUserSuccess,
    addUserFailed,
    loginSuccess,
    loginFailure,
    verifyRequest,
    verifySuccess,
    loginRequest,
    logoutRequest,
    logoutSuccess,
    logoutFailure,
    getRolesFailed,
    getRolesSuccess,
    getUsersByCompanyIdSuccess,
    updateUserSuccess,
    getUsersByCompanyId,
} from './actions';

import {
    
    verifyCompanyStatus,
} from '../admin/actions';

import logoutObserver from '../../firebase/logoutObserver';

import { postActionOnAPI, getActionOnAPI,putActionOnAPI,deleteActionOnAPI } from '../../helpers/backend';
const apiUrl = process.env.REACT_APP_ACCOUNTS_API_URL;
const amApiUrl = process.env.REACT_APP_AM_API_URL;

async function callApi() {
    try {
        let response = await axios.get(process.env.REACT_APP_ACCOUNTS_API_URL + '/users', {
            crossDomain: true,
            withCredentials: true,
        });
        return response;
    } catch (e) {
        return e.response.status;
    }
}

async function fetchUser() {
    try {
        const response = await callApi();
        let user = null;

        if (typeof response === 'object' && response.hasOwnProperty('data')) {
            user = response.data.user;
        }

        return user;
    } catch (e) {
        if (e.response.status == 403) {
            return null;
        }

        return 'unreachable';
    }
}

function* loginUser(action) {
    const user = yield call(fetchUser);
    if (user === 'unreachable' || user===null || user === 403  ) {
        yield put(loginFailure());
        window.location.href = process.env.REACT_APP_LOGOUT_URL
    } else {
        if (user !== null && typeof user === 'object') {
            logoutObserver.session = true;
            yield put(loginSuccess({ ...user }));
            yield put(verifyCompanyStatus(user.company._id));
        } else {
            window.location.href = process.env.REACT_APP_AUTH_URL;
        }
    }
}

function* logoutUser() {
    yield put(logoutRequest());
    try {
        yield put(logoutSuccess());
    } catch (e) {
        yield put(logoutFailure());
    }
}

function* watchForSessionChange() {
    // This is where you wait for a callback from firebase
    const channel = yield call({ context: logoutObserver, fn: 'getAuthChannel' });

    try {
        while (true) {
            const result = yield take(channel);
            yield put(verifyRequest());
            if (result.session === false) {
                channel.close();
                window.location.href = process.env.REACT_APP_AUTH_URL;
            } else {
                const user = yield call(fetchUser);
                if (user === 'unreachable' || user===null ) {
                    yield put(loginFailure());
                    yield put(push('/error/401'));
                    channel.close();
                } else {
                    if (user !== null && typeof user === 'object') {
                        yield put(loginSuccess({ ...user }));
                    } else {
                        channel.close();
                        window.location.href = process.env.REACT_APP_AUTH_URL;
                    }
                }
            }
            yield put(verifySuccess());
        }
    } finally {
        if (yield cancelled()) {
            channel.close();
            window.location.href = process.env.REACT_APP_AUTH_URL;
        }
    }
}

function* verifyAuth() {
    yield put(verifyRequest());
    if (null === document.cookie.match(/^(.*;)?\s*sessionObserver\s*=\s*[^;]+(.*)?$/)) {
        window.location.href = process.env.REACT_APP_AUTH_URL;
    } else {
        yield put(loginRequest());
    }
}

function* addUserToFirebase(action) {
    const response = yield call(postActionOnAPI, `${apiUrl}/users`, action.payload);
    if (response.status.error) {
        yield put(addUserFailed(response.status.error));
        return;
    }
    if (response === 'Unauthorized') {
        yield put(addUserFailed('Unauthorized'));
        return;
    }
    yield put(addUserSuccess('User added successfully'));
    yield put(getUsersByCompanyId(action.payload.company._id));
}

function* getRolesFromAPI(action) {
    const response = yield call(getActionOnAPI, `${amApiUrl}/roles`);
    if (response === 'Unauthorized') {
        yield put(getRolesFailed('getRoleFailed'));
    } else {
        yield put(getRolesSuccess(response));
    }
}

function* getUsersByCompanyIdFromAPI(action) {
    const response = yield call(getActionOnAPI, `${apiUrl}/users/companies/${action.payload}`);
    if (response === 'Unauthorized') {
        // yield put(getRolesFailed('getRoleFailed'));
    } else {
        yield put(getUsersByCompanyIdSuccess(response));
    }
}

function* updateUser(action) {
    const response = yield call(putActionOnAPI, `${apiUrl}/users/${action.payload._id}`, action.payload);
    if (response === 'Unauthorized') {
      //  yield put(updateAppFailed('updateAppErrorMessage'));
    } else {
         yield put(getUsersByCompanyId(action.payload.company._id));
    }
}

function* deleteUser(action) {
    const response = yield call(deleteActionOnAPI, `${apiUrl}/users/${action.payload.firebaseId}`);
    if (response === 'Unauthorized') {
      //  yield put(updateAppFailed('updateAppErrorMessage'));
    } else {
         yield put(getUsersByCompanyId(action.payload.company._id));
    }
}

function* updateUsersCompanyName(action) {
    const response = yield call(putActionOnAPI, `${apiUrl}/users/company/${action.payload._id}`,action.payload);
    if (response === 'Unauthorized') {
      //  yield put(updateAppFailed('updateAppErrorMessage'));
    }
}


export function* watchLoginRequest() {
    yield takeEvery(LOGIN_REQUEST, loginUser);
}

export function* watchVerifyRequest() {
    yield takeEvery(SESSION_CHECK, verifyAuth);
}
export function* watchUserLogout() {
    yield takeEvery(LOGOUT_REQUEST, logoutUser);
}

export function* watchAddUser() {
    yield takeEvery(ADD_USER, addUserToFirebase);
}

export function* watchGetRoles() {
    yield takeEvery(GET_ROLES, getRolesFromAPI);
}

export function* watchUsersByCompanyId() {
    yield takeEvery(GET_USERS_BY_COMPANY_ID, getUsersByCompanyIdFromAPI);
}

export function* watchUpdateUser() {
    yield takeEvery(UPDATE_USER, updateUser);
}

export function* watchDeleteUser() {
    yield takeEvery(DELETE_USER, deleteUser);
}

export function* watchUpdateUsersCompanyName() {
    yield takeEvery(UPDATE_USERS_COMPANY_NAME, updateUsersCompanyName);
}




function* userSaga() {
    yield all([
        fork(watchVerifyRequest),
        fork(watchLoginRequest),
        fork(watchUserLogout),
        fork(watchAddUser),
        fork(watchForSessionChange),
        fork(watchGetRoles),
        fork(watchUsersByCompanyId),
        fork(watchUpdateUser),
        fork(watchDeleteUser),
        fork(watchUpdateUsersCompanyName),
    ]);
}

export default userSaga;

import {
    GET_DIAGNOSTIC_SUCCESS,
    GET_DIAGNOSTIC_FAILED,
    ADD_STEP_LISTING_SUCCESS,
    ADD_STEP_LISTING_FAILED,
    REMOVE_STEP_LISTING_SUCCESS,
    REMOVE_STEP_LISTING_FAILED,
    UPDATE_STEP_LISTING,
    UPDATE_STEP_LISTING_SUCCESS,
    UPDATE_STEP_LISTING_FAILED,
    ADD_STEP_SUCCESS,
    ADD_STEP_FAILED,
    UPDATE_STEP_SUCCESS,
    UPDATE_STEP_FAILED,
    REMOVE_STEP_SUCCESS,
    REMOVE_STEP_FAILED,
    GET_STEP_LISTING_SUCCESS,
    GET_STEP_LISTING_FAILED,
    GET_STEP,
    GET_STEPS_SUCCESS,
    GET_STEPS_FAILED,
    GET_STEP_SUCCESS,
    GET_STEP_FAILED,
    ADD_APPS_TO_STEP_SUCCESS,
    ADD_APPS_TO_STEP_FAILED,
    MODIFY_STEP_SUCCESS,
    REMOVE_MODULE_FROM_STEP_SUCCESS,
    REMOVE_MODULE_FROM_STEP_FAILED,
    GET_DIAGNOSTICS_BY_APP_ID_SUCCESS,
} from './constants';

const INIT_STATE = {
    loading: false,
    diagnostic: [],
    stepListing: {},
    steps: [],
    diagnosticsWithAppId: [],
    step: {},
    loadingStep: false,
    message: '',
    error: '',
    stepsPendingChanges: false,
};

const Diagnostic = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_DIAGNOSTIC_SUCCESS:
            return { ...state, diagnostic: action.payload, loading: false };
        case GET_DIAGNOSTIC_FAILED:
            return { ...state, error: action.payload, loading: false };
        case ADD_STEP_LISTING_SUCCESS:
            return { ...state, message: action.payload, loading: false };
        case ADD_STEP_LISTING_FAILED:
            return { ...state, error: action.payload, loading: false };
        case REMOVE_STEP_LISTING_SUCCESS:
            return { ...state, message: action.payload, loading: false };
        case REMOVE_STEP_LISTING_FAILED:
            return { ...state, error: action.payload, loading: false };
        case UPDATE_STEP_LISTING_SUCCESS:
            return { ...state, message: action.payload, loading: false };
        case UPDATE_STEP_LISTING_FAILED:
            return { ...state, error: action.payload, loading: false };
        case ADD_STEP_SUCCESS:
            return { ...state, message: action.payload, loading: false };
        case ADD_STEP_FAILED:
            return { ...state, error: action.payload, loading: false };
        case UPDATE_STEP_SUCCESS:
            return { ...state, message: action.payload, loading: false };
        case UPDATE_STEP_FAILED:
            return { ...state, error: action.payload, loading: false };
        case REMOVE_STEP_SUCCESS:
            return { ...state, message: action.payload, loading: false };
        case REMOVE_STEP_FAILED:
            return { ...state, error: action.payload, loading: false };
        case GET_STEP_LISTING_SUCCESS:
            return { ...state, stepListing: action.payload, loading: false };
        case GET_STEP_LISTING_FAILED:
            return { ...state, error: action.payload, loading: false };
        case GET_STEPS_SUCCESS:
            return { ...state, steps: action.payload, loading: false };
        case GET_DIAGNOSTICS_BY_APP_ID_SUCCESS:
            return { ...state, diagnosticsWithAppId: action.payload, loading: false };
        case GET_STEPS_FAILED:
            return { ...state, error: action.payload, loading: false };
        case GET_STEP:
            return { ...state, error: action.payload, loadingStep: true };
        case GET_STEP_SUCCESS:
            return { ...state, step: action.payload, loadingStep: false };
        case GET_STEP_FAILED:
            return { ...state, error: action.payload, loadingStep: false };
        case ADD_APPS_TO_STEP_SUCCESS:
            return { ...state, message: action.payload, loading: false };
        case ADD_APPS_TO_STEP_FAILED:
            return { ...state, error: action.payload, loading: false };
        case REMOVE_MODULE_FROM_STEP_SUCCESS:
            return { ...state, message: action.payload, loading: false };
        case REMOVE_MODULE_FROM_STEP_FAILED:
            return { ...state, error: action.payload, loading: false };
        case MODIFY_STEP_SUCCESS:
            return { ...state, stepsPendingChanges: action.payload };
        default:
            return { ...state };
    }
};

export default Diagnostic;

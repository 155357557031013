// @flow

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import Layout from './layout/reducers';
import AppMenu from './appMenu/reducers';
import User from './user/reducers';
import Admin from './admin/reducers';
import Cart from './cart/reducers';
import Subscription from './subscription/reducers';
import Diagnostic from './diagnostic/reducers';
import Marketing from './marketing/reducers';

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    AppMenu,
    Layout,
    User,
    Admin,
    Cart,
    Subscription,
    Diagnostic,
    Marketing
})

export default createRootReducer

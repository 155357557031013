import { eventChannel } from 'redux-saga'
class LogoutObserver {
    constructor()
    {
        this._authChannel = null;
        this._session = null;
    }

    get session() {
        return this._session;
    }

    set session(value) {
        this._session = value;
    }


    getAuthChannel()
    {
        if (!this.authChannel) {
            this.authChannel = eventChannel(emit => {

                const iv = setInterval(() => {
                    let sessionExist = false;
                    if ( null === document.cookie.match(/^(.*;)?\s*sessionObserver\s*=\s*[^;]+(.*)?$/) )
                    {
                        sessionExist = false;
                    }
                    else {
                        sessionExist = true;
                    }
                    if(this._session === null || this._session !== sessionExist)
                    {
                        this._session = sessionExist;
                        emit({session:this._session})
                    }
                }, 3000);
                // The subscriber must return an unsubscribe function
                return () => {

                    clearInterval(iv)
                }

            });
        }
        return this.authChannel;
    }

}

export default new LogoutObserver();
//const baseDb = myFirebase.firestore();
//export const db = baseDb;
import axios from 'axios';

export const getActionOnAPI = url => {
    return axios
        .get(url, {
            crossDomain: true,
            withCredentials: true,
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            return err;
        });
};

export const postActionOnAPI = (url, body) => {
    return axios
        .post(url, body, {
            crossDomain: true,
            withCredentials: true,
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            return err;
        });
};

export const putActionOnAPI = (url, body) => {
    return axios
        .put(url, body, {
            crossDomain: true,
            withCredentials: true,
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            return err;
        });
};

export const deleteActionOnAPI = url => {
    return axios
        .delete(url, {
            crossDomain: true,
            withCredentials: true,
        })
        .then(response => {
            return response;
        })
        .catch(err => {
            return err;
        });
};

import {
    ADD_TO_CART,
    REMOVE_APP_FROM_CART,
    GET_CART,
    GET_CART_SUCCESS,
    GET_CART_FAILED,
    UPDATE_CART_ITEM,
    GET_CARTS_BY_APP_ID,
    GET_CARTS_BY_APP_ID_SUCCESS,
} from './constants';

const INIT_STATE = {
    loading: true,
    cartItems: [],
    filteredCarts : [],
    cart: {},
    successMessage: '',
    errorMessage: '',
};

const Cart = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CART:
            return { ...state, loading: true };
        case GET_CART_SUCCESS:
            return { ...state, cartItems: action.payload.cartItems, cart: action.payload.cart, loading: false };
        case GET_CARTS_BY_APP_ID_SUCCESS:
                return { ...state, filteredCarts: action.payload };
        case GET_CART_FAILED:
            return { ...state, error: action.payload, loading: false };
        case ADD_TO_CART:
            return { ...state, loading: true };
        case UPDATE_CART_ITEM:
            return { ...state, loading: true };
        case GET_CARTS_BY_APP_ID:
                return { ...state, loading: true };
        default:
            return { ...state };
    }
};

export default Cart;

export const GET_SUBSCRIPTION = "GET_SUBSCRIPTION"
export const GET_SUBSCRIPTION_SUCCESS = "GET_SUBSCRIPTION_SUCCESS"
export const GET_SUBSCRIPTION_FAILED = "GET_SUBSCRIPTION_FAILED"
export const ADD_TO_SUBSCRIPTION = "ADD_TO_SUBSCRIPTION"
export const REMOVE_MODULE_FROM_SUBSCRIPTION = "REMOVE_MODULE_FROM_SUBSCRIPTION"
export const REMOVE_MODULE_FROM_SUBSCRIPTION_SUCCESS = "REMOVE_MODULE_FROM_SUBSCRIPTION_SUCCESS"
export const REMOVE_MODULE_FROM_SUBSCRIPTION_FAILED = "REMOVE_MODULE_FROM_SUBSCRIPTION_FAILED"
export const REMOVE_APP_FROM_SUBSCRIPTION = "REMOVE_APP_FROM_SUBSCRIPTION"
export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION"
export const ADD_ATTACHMENT_TO_SUBSCRIPTION = "ADD_ATTACHMENT_TO_SUBSCRIPTION"
export const ADD_ATTACHMENT_TO_SUBSCRIPTION_SUCCESS = "ADD_ATTACHMENT_TO_SUBSCRIPTION_SUCCESS"
export const GET_SUBSCRIPTIONS_BY_APP_ID = "GET_SUBSCRIPTIONS_BY_APP_ID"
export const GET_SUBSCRIPTIONS_BY_APP_ID_SUCCESS = "GET_SUBSCRIPTIONS_BY_APP_ID_SUCCESS"

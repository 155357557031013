import { 
    ADD_TO_CART,
    REMOVE_APP_FROM_CART,
    REMOVE_MODULE_FROM_CART,
    REMOVE_APPS_FROM_CART,
    GET_CART,
    GET_CART_SUCCESS,
    GET_CART_FAILED,
    UPDATE_CART_ITEM,
    UPDATE_CART,
    GET_CARTS_BY_APP_ID,
    GET_CARTS_BY_APP_ID_SUCCESS,
} from './constants';

export const addToCart = (company, item) => ({
    type: ADD_TO_CART,
    payload: {
        company: company,
        item: item
    }
});

export const removeAppFromCart = (company, appIdToRemove) => ({
    type: REMOVE_APP_FROM_CART,
    payload: {
        company: company,
        id: appIdToRemove
    }
});

export const removeModuleFromCart = (company, appIdToRemove, moduleIdToRemove) => ({
    type: REMOVE_MODULE_FROM_CART,
    payload: {
        company: company,
        appId: appIdToRemove,
        moduleId: moduleIdToRemove
    }
});

export const removeAppsFromCart = (company) => ({
    type: REMOVE_APPS_FROM_CART,
    payload: company
});

export const getCart = (company) => ({
    type: GET_CART,
    payload: company
});

export const getCartSuccess = (cartItems) => ({
    type: GET_CART_SUCCESS,
    payload: cartItems
});

export const getCartFailed = (error) => ({
    type: GET_CART_FAILED,
    payload: error
});

export const updateCartItem = (company, cartItemId, cartItem) => ({
    type: UPDATE_CART_ITEM,
    payload: {
        company: company,
        cartItemId: cartItemId,
        cartItem: cartItem
    }
});

export const updateCart = (company, cart) => ({
    type: UPDATE_CART,
    payload: {
        company: company,
        cart: cart
    }
});

export const getCartsByAppId = (appId) => ({
    type: GET_CARTS_BY_APP_ID,
    payload: appId
});

export const getCartsByAppIdSuccess = (filteredCarts) => ({
    type: GET_CARTS_BY_APP_ID_SUCCESS,
    payload: filteredCarts
});
import {
    GET_SUBSCRIPTION,
    GET_SUBSCRIPTION_SUCCESS,
    GET_SUBSCRIPTION_FAILED,
    ADD_ATTACHMENT_TO_SUBSCRIPTION_SUCCESS,
    GET_SUBSCRIPTIONS_BY_APP_ID_SUCCESS,
} from './constants';

const INIT_STATE = {
    loading: false,
    subscription: {},
    filteredSubscriptions: [],
    apps: [],
    error: {},
    redirectToSubscription: false,
};

const Subscription = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_SUBSCRIPTION:
            return { ...state, redirectToSubscription: false, loading: true };
        case GET_SUBSCRIPTION_SUCCESS:
            return { ...state, subscription: action.payload, apps: action.payload.apps, loading: false };
        case GET_SUBSCRIPTIONS_BY_APP_ID_SUCCESS:
            return { ...state, filteredSubscriptions: action.payload };
        case GET_SUBSCRIPTION_FAILED:
            return { ...state, error: action.payload, loading: false };
        case ADD_ATTACHMENT_TO_SUBSCRIPTION_SUCCESS:
            return { ...state, redirectToSubscription: true };
        default:
            return { ...state };
    }
};

export default Subscription;

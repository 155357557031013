export const GET_DIAGNOSTIC = "GET_DIAGNOSTIC"
export const GET_DIAGNOSTIC_SUCCESS = "GET_DIAGNOSTIC_SUCCESS"
export const GET_DIAGNOSTIC_FAILED = "GET_DIAGNOSTIC_FAILED"

export const GET_STEP_LISTING = "GET_STEP_LISTING"
export const GET_STEP_LISTING_SUCCESS = "GET_STEP_LISTING_SUCCESS"
export const GET_STEP_LISTING_FAILED = "GET_STEP_LISTING_FAILED"

export const GET_STEPS = "GET_STEPS"
export const GET_STEPS_SUCCESS = "GET_STEPS_SUCCESS"
export const GET_STEPS_FAILED = "GET_STEPS_FAILED"

export const GET_STEP = "GET_STEP"
export const GET_STEP_SUCCESS = "GET_STEP_SUCCESS"
export const GET_STEP_FAILED = "GET_STEP_FAILED"

export const ADD_STEP_LISTING = "ADD_STEP_LISTING"
export const ADD_STEP_LISTING_SUCCESS = "ADD_STEP_LISTING_SUCCESS"
export const ADD_STEP_LISTING_FAILED = "ADD_STEP_LISTING_FAILED"

export const REMOVE_STEP_LISTING = "REMOVE_STEP_LISTING"
export const REMOVE_STEP_LISTING_SUCCESS = "REMOVE_STEP_LISTING_SUCCESS"
export const REMOVE_STEP_LISTING_FAILED = "REMOVE_STEP_LISTING_FAILED"

export const UPDATE_STEP_LISTING = "UPDATE_STEP_LISTING"
export const UPDATE_STEP_LISTING_SUCCESS = "UPDATE_STEP_LISTING_SUCCESS"
export const UPDATE_STEP_LISTING_FAILED = "UPDATE_STEP_LISTING_FAILED"

export const ADD_STEP = "ADD_STEP"
export const ADD_STEP_SUCCESS = "ADD_STEP_SUCCESS"
export const ADD_STEP_FAILED = "ADD_STEP_FAILED"

export const UPDATE_STEP = "UPDATE_STEP"
export const UPDATE_STEP_SUCCESS = "UPDATE_STEP_SUCCESS"
export const UPDATE_STEP_FAILED = "UPDATE_STEP_FAILED"

export const REMOVE_STEP = "REMOVE_STEP"
export const REMOVE_STEP_SUCCESS = "REMOVE_STEP_SUCCESS"
export const REMOVE_STEP_FAILED = "REMOVE_STEP_FAILED"

export const ADD_APPS_TO_STEP = "ADD_APPS_TO_STEP"
export const ADD_APPS_TO_STEP_SUCCESS = "ADD_APPS_TO_STEP_SUCCESS"
export const ADD_APPS_TO_STEP_FAILED = "ADD_APPS_TO_STEP_FAILED"

export const MODIFY_STEP_SEQUENCE = "MODIFY_STEP_SEQUENCE"
export const MODIFY_STEP_SUCCESS = "MODIFY_STEP_SUCCESS"

export const REMOVE_MODULE_FROM_STEP = "REMOVE_MODULE_FROM_STEP"
export const REMOVE_MODULE_FROM_STEP_SUCCESS = "REMOVE_MODULE_FROM_STEP_SUCCESS"
export const REMOVE_MODULE_FROM_STEP_FAILED = "REMOVE_MODULE_FROM_STEP_FAILED"

export const GET_DIAGNOSTICS_BY_APP_ID = "GET_DIAGNOSTICS_BY_APP_ID"

export const GET_DIAGNOSTICS_BY_APP_ID_SUCCESS = "GET_DIAGNOSTICS_BY_APP_ID_SUCCESS"
export const DELETE_MODULE_FROM_ALL_STEPS = "DELETE_MODULE_FROM_ALL_STEPS"

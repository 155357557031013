import {
    UPDATE_PUBLICITY,
    UPDATE_PUBLICITY_SUCCESS,
    UPDATE_PUBLICITY_FAILED,
    GET_PUBLICITY_BY_ID,
    GET_PUBLICITY_BY_ID_SUCCESS,
    GET_PUBLICITY_BY_ID_FAILED,
    DELETE_PUBLICITY,
    DELETE_PUBLICITY_SUCCESS,
    DELETE_PUBLICITY_FAILED,
    ADD_PUBLICITY,
    ADD_PUBLICITY_SUCCESS,
    ADD_PUBLICITY_FAILED,
    GET_ADVERTISEMENTS,
    GET_ADVERTISEMENTS_SUCCESS,
    GET_ADVERTISEMENTS_FAILED

} from './constants';

export const getAdvertisements = (advertisements) => ({
    type: GET_ADVERTISEMENTS,
    payload:advertisements
})


export const getAdvertisementsSuccess = (advertisements) => ({
    type: GET_ADVERTISEMENTS_SUCCESS,
    payload:advertisements
})

export const getAdvertisementsFailed = (error) => ({
    type: GET_ADVERTISEMENTS_FAILED,
    payload: error
})

export const addPublicity = (publicity) => ({
    type: ADD_PUBLICITY,
    payload: publicity
});

export const addPublicitySuccess = (message) => ({
    type: ADD_PUBLICITY_SUCCESS,
    payload: message
});

export const addPublicityFailed = (error) => ({
    type: ADD_PUBLICITY_FAILED,
    payload: error
});

export const updatePublicity = (advertisement_id, advertisement) => ({
    type: UPDATE_PUBLICITY,
    payload: {
        _id : advertisement_id,
        publicity : advertisement
    },
});

export const updatePublicitySuccess = (message) => ({
    type: UPDATE_PUBLICITY_SUCCESS,
    payload: message
});

export const updatePublicityFailed = (error) => ({
    type: UPDATE_PUBLICITY_FAILED,
    payload: error
});

export const deletePublicity = (PublicityId) => ({
    type: DELETE_PUBLICITY,
    payload: PublicityId
});

export const deletePublicitySuccess = (message) => ({
    type: DELETE_PUBLICITY_SUCCESS,
    payload: message
});

export const deletePublicityFailed = (error) => ({
    type: DELETE_PUBLICITY_FAILED,
    payload: error
});





export const getPublicityById = (PublicityID) => ({
    type: GET_PUBLICITY_BY_ID,
    payload: PublicityID
});

export const getPublicityByIdFailed = (error) => ({
    type: GET_PUBLICITY_BY_ID_FAILED,
    payload: error
});

export const getPublicityByIdSuccess= (message) => ({
    type: GET_PUBLICITY_BY_ID_SUCCESS,
    payload: message
});




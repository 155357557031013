import {
    GET_GEARS_SUCCESS,
    GET_GEARS_FAILED,
    GET_APPS_SUCCESS,
    GET_APPS_FAILED,
    GET_APP,
    GET_APP_SUCCESS,
    GET_APP_FAILED,
    GET_INDUSTRIES_SUCCESS,
    GET_INDUSTRIES_FAILED,
    ADD_APP,
    ADD_APP_SUCCESS,
    ADD_APP_FAILED,
    UPDATE_APP,
    UPDATE_APP_SUCCESS,
    UPDATE_APP_FAILED,
    DELETE_APP,
    DELETE_APP_SUCCESS,
    DELETE_APP_FAILED,
    ADD_COMPANY,
    ADD_COMPANY_SUCCESS,
    ADD_COMPANY_FAILED,
    GET_COMPANIES_SUCCESS,
    GET_COMPANIES_FAILED,
    GET_COMPANY,
    GET_COMPANY_SUCCESS,
    GET_COMPANY_FAILED,
    CLEAR_MESSAGES,
    CLEAR_ERRORS,
    UPDATE_COMPANY,
    UPDATE_COMPANY_SUCCESS,
    UPDATE_COMPANY_FAILED,
    CLEAR_APP,
    GET_MODULES_SUCCESS,
    GET_MODULES_FAILED,
    GET_MODULES_BY_APP_ID,
    VERIFY_COMPANY_STATUS_RESULT
} from './constants';

const INIT_STATE = {
    apps: [],
    app: [],
    gears: [],
    industries: [],
    error: '',
    loading: false,
    message: '',
    isVerifying: true,
    isCompanyStatusActive:false,
    company: {},
    companies: [],
    modules: [],
};

const Admin = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_APPS_SUCCESS:
            return { ...state, apps: action.payload, loading: false };
        case GET_APPS_FAILED:
            return { ...state, error: action.payload, loading: false };
        case GET_APP:
            return { ...state, loading: true };
        case GET_APP_SUCCESS:
            return { ...state, app: action.payload, loading: false };
        case GET_APP_FAILED:
            return { ...state, error: action.payload, loading: false };
        case GET_GEARS_SUCCESS:
            return { ...state, gears: action.payload, loading: false };
        case GET_GEARS_FAILED:
            return { ...state, error: action.payload, loading: false };
        case GET_INDUSTRIES_SUCCESS:
            return { ...state, industries: action.payload, loading: false };
        case GET_INDUSTRIES_FAILED:
            return { ...state, error: action.payload, loading: false };
        case ADD_APP:
            return { ...state, loading: true };
        case ADD_APP_SUCCESS:
            return { ...state, message: action.payload, loading: false };
        case ADD_APP_FAILED:
            return { ...state, error: action.payload, loading: false };
        case UPDATE_APP:
            return { ...state, loading: true };
        case UPDATE_APP_SUCCESS:
            return { ...state, message: action.payload, loading: false };
        case UPDATE_APP_FAILED:
            return { ...state, error: action.payload, loading: false };
        case DELETE_APP:
            return { ...state, loading: true };
        case DELETE_APP_SUCCESS:
            return { ...state, message: action.payload, loading: false };
        case DELETE_APP_FAILED:
            return { ...state, error: action.payload, loading: false };
        case ADD_COMPANY:
            return {...state, loading: true};
        case ADD_COMPANY_SUCCESS:
            return { ...state, message: action.payload, loading: false };
        case ADD_COMPANY_FAILED:
            return { ...state, error: action.payload, loading: false };
        case GET_COMPANIES_SUCCESS:
            return { ...state, companies: action.payload, loading: false };
        case GET_COMPANIES_FAILED:
            return { ...state, error: action.payload, loading: false };
        case GET_COMPANY:
            return { ...state, company: action.payload, loading: false };
        case GET_COMPANY_SUCCESS:
            return { ...state, company: action.payload, loading: false };
        case GET_COMPANY_FAILED:
            return { ...state, error: action.payload, loading: false };
        case UPDATE_COMPANY: 
            return { ...state, loading: true };
        case UPDATE_COMPANY_SUCCESS:
            return { ...state, message: action.payload, loading: false };
        case UPDATE_COMPANY_FAILED:
            return { ...state, error: action.payload, loading: false };
        case CLEAR_MESSAGES:
            return { ...state, message: action.payload };
        case CLEAR_ERRORS:
            return { ...state, error: action.payload };
        case CLEAR_APP:
            return { ...state, app: action.payload };
        case GET_MODULES_SUCCESS:
            return { ...state, modules: action.payload, loading: false };
        case GET_MODULES_FAILED:
            return { ...state, error: action.payload, loading: false };
        case GET_MODULES_BY_APP_ID:
            return { ...state, modules: action.payload, loading: false };
        case VERIFY_COMPANY_STATUS_RESULT:
            return { ...state, isCompanyStatusActive: action.payload,isVerifying: false };
        default:
            return { ...state };
    }
};

export default Admin;

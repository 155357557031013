import {
    GET_DIAGNOSTIC,
    GET_DIAGNOSTIC_SUCCESS,
    GET_DIAGNOSTIC_FAILED,
    ADD_STEP_LISTING,
    ADD_STEP_LISTING_SUCCESS,
    ADD_STEP_LISTING_FAILED,
    REMOVE_STEP,
    REMOVE_STEP_SUCCESS,
    REMOVE_STEP_FAILED,
    REMOVE_STEP_LISTING,
    REMOVE_STEP_LISTING_SUCCESS,
    REMOVE_STEP_LISTING_FAILED,
    UPDATE_STEP_LISTING,
    UPDATE_STEP_LISTING_SUCCESS,
    UPDATE_STEP_LISTING_FAILED,
    ADD_STEP,
    ADD_STEP_SUCCESS,
    ADD_STEP_FAILED,
    UPDATE_STEP,
    UPDATE_STEP_SUCCESS,
    UPDATE_STEP_FAILED,
    GET_STEP_LISTING,
    GET_STEP_LISTING_SUCCESS,
    GET_STEP_LISTING_FAILED,
    GET_STEPS,
    GET_STEPS_SUCCESS,
    GET_STEPS_FAILED,
    GET_STEP,
    GET_STEP_SUCCESS,
    GET_STEP_FAILED,
    ADD_APPS_TO_STEP,
    ADD_APPS_TO_STEP_SUCCESS,
    ADD_APPS_TO_STEP_FAILED,
    MODIFY_STEP_SEQUENCE,
    MODIFY_STEP_SUCCESS,
    REMOVE_MODULE_FROM_STEP,
    REMOVE_MODULE_FROM_STEP_SUCCESS,
    REMOVE_MODULE_FROM_STEP_FAILED,
    GET_DIAGNOSTICS_BY_APP_ID,
    GET_DIAGNOSTICS_BY_APP_ID_SUCCESS,
    DELETE_MODULE_FROM_ALL_STEPS
} from './constants';

export const getDiagnostic = (company) => ({
    type: GET_DIAGNOSTIC,
    payload: company,
});

export const getDiagnosticSuccess = (diagnostic) => ({
    type: GET_DIAGNOSTIC_SUCCESS,
    payload: diagnostic,
});

export const getDiagnosticFailed = (error) => ({
    type: GET_DIAGNOSTIC_FAILED,
    payload: error,
});

export const addStepListing = (company, stepListing) => ({
    type: ADD_STEP_LISTING,
    payload: {
        company: company,
        stepListing: stepListing,
    },
});

export const addStepListingSuccess = (message) => ({
    type: ADD_STEP_LISTING_SUCCESS,
    payload: message,
});

export const addStepListingFailed = (error) => ({
    type: ADD_STEP_LISTING_FAILED,
    payload: error,
});

export const removeStepListing = (company, stepListingId) => ({
    type: REMOVE_STEP_LISTING,
    payload: {
        company: company,
        stepListingId: stepListingId,
    },
});

export const removeStepListingSuccess = (message) => ({
    type: REMOVE_STEP_LISTING_SUCCESS,
    payload: message,
});

export const removeStepListingFailed = (error) => ({
    type: REMOVE_STEP_LISTING_FAILED,
    payload: error,
});

export const updateStepListing = (company, stepListingId, stepListing) => ({
    type: UPDATE_STEP_LISTING,
    payload: {
        company: company,
        stepListingId: stepListingId,
        stepListing: stepListing,
    },
});

export const updateStepListingSuccess = (message) => ({
    type: UPDATE_STEP_LISTING_SUCCESS,
    payload: message,
});

export const updateStepListingFailed = (error) => ({
    type: UPDATE_STEP_LISTING_FAILED,
    payload: error,
});

export const addStep = (companyId, stepListingId, step) => ({
    type: ADD_STEP,
    payload: {
        companyId: companyId,
        stepListingId: stepListingId,
        step: step,
    },
});

export const addStepSuccess = (message) => ({
    type: ADD_STEP_SUCCESS,
    payload: message,
});

export const addStepFailed = (error) => ({
    type: ADD_STEP_FAILED,
    payload: error,
});

export const updateStep = (companyId, stepListingId, step) => ({
    type: UPDATE_STEP,
    payload: {
        companyId: companyId,
        stepListingId: stepListingId,
        step: step,
        stepId: step._id,
    },
});

export const updateStepSuccess = (message) => ({
    type: UPDATE_STEP_SUCCESS,
    payload: message,
});

export const updateStepFailed = (error) => ({
    type: UPDATE_STEP_FAILED,
    payload: error,
});

export const removeStep = (companyId, stepListingId, stepId) => ({
    type: REMOVE_STEP,
    payload: {
        companyId: companyId,
        stepListingId: stepListingId,
        stepId: stepId,
    },
});

export const removeStepSuccess = (message) => ({
    type: REMOVE_STEP_SUCCESS,
    payload: message,
});

export const removeStepFailed = (error) => ({
    type: REMOVE_STEP_FAILED,
    payload: error,
});

export const getStepListing = (companyId, stepListingId) => ({
    type: GET_STEP_LISTING,
    payload: {
        companyId: companyId,
        stepListingId: stepListingId,
    },
});

export const getStepListingSuccess = (stepListing) => ({
    type: GET_STEP_LISTING_SUCCESS,
    payload: stepListing,
});

export const getStepListingFailed = (error) => ({
    type: GET_STEP_LISTING_FAILED,
    payload: error,
});

export const getSteps = (companyId, stepListingId) => ({
    type: GET_STEPS,
    payload: {
        companyId: companyId,
        stepListingId: stepListingId,
    },
});

export const getStepsSuccess = (steps) => ({
    type: GET_STEPS_SUCCESS,
    payload: steps,
});

export const getStepsFailed = (error) => ({
    type: GET_STEPS_FAILED,
    payload: error,
});

export const getStep = (companyId, stepListingId, stepId) => ({
    type: GET_STEP,
    payload: {
        companyId: companyId,
        stepListingId: stepListingId,
        stepId: stepId
    },
});

export const getStepSuccess = (step) => ({
    type: GET_STEP_SUCCESS,
    payload: step,
});

export const getStepFailed = (error) => ({
    type: GET_STEP_FAILED,
    payload: error,
});


export const addAppsToStep = (companyId, stepListingId, stepId, apps, modules) => ({
    type: ADD_APPS_TO_STEP,
    payload: {
        companyId: companyId,
        stepListingId: stepListingId,
        stepId: stepId,
        apps: apps,
        modules: modules,
    },
});

export const removeModuleFromStepSuccess = (message) => ({
    type: REMOVE_MODULE_FROM_STEP_SUCCESS,
    payload: message
});

export const removeModuleFromStepFailed = (error) => ({
    type: REMOVE_MODULE_FROM_STEP_FAILED,
    payload: error
});

export const removeModuleFromStep = (companyId, stepListingId, stepId, moduleId) => ({
    type: REMOVE_MODULE_FROM_STEP,
    payload: {
        companyId: companyId,
        stepListingId: stepListingId,
        stepId: stepId,
        moduleId: moduleId
    },
});

export const addAppsToStepSuccess = (message) => ({
    type: ADD_APPS_TO_STEP_SUCCESS,
    payload: message,
});

export const addAppsToStepFailed = (error) => ({
    type: ADD_APPS_TO_STEP_FAILED,
    payload: error,
});

export const modifyStepSequence = (companyId, stepListingId, stepId, sequenceModifier) => ({
    type: MODIFY_STEP_SEQUENCE,
    payload: {
        companyId: companyId,
        stepListingId: stepListingId,
        stepId: stepId,
        sequenceModifier: sequenceModifier,
    },
});

export const modifyStepSuccess = (pendingChanges) => ({
    type: MODIFY_STEP_SUCCESS,
    payload: pendingChanges,
});

export const getDiagnosticsByAppId = (appId) => ({
    type: GET_DIAGNOSTICS_BY_APP_ID,
    payload: appId,
});

export const getDiagnosticsByAppIdSuccess = (response) => ({
    type: GET_DIAGNOSTICS_BY_APP_ID_SUCCESS,
    payload: response,
});

export const deleteModuleFromAllSteps = (moduleId) => ({
    type: DELETE_MODULE_FROM_ALL_STEPS,
    payload: moduleId,
});
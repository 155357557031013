import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { getActionOnAPI, postActionOnAPI, putActionOnAPI, deleteActionOnAPI } from '../../helpers/backend';
import {
    GET_SUBSCRIPTION,
    ADD_TO_SUBSCRIPTION,
    REMOVE_MODULE_FROM_SUBSCRIPTION,
    REMOVE_APP_FROM_SUBSCRIPTION,
    UPDATE_SUBSCRIPTION,
    ADD_ATTACHMENT_TO_SUBSCRIPTION,
    GET_SUBSCRIPTIONS_BY_APP_ID,
} from './constants';
import {
    getSubscriptionSuccess,
    getSubscriptionFailed,
    removeModuleFromSubscriptionFailed,
    getSubscriptionsByAppIdSuccess,
} from './actions';

const apiUrl = process.env.REACT_APP_AM_API_URL;

function* getSubscription(action) {
    const response = yield call(getActionOnAPI, `${apiUrl}/subscriptions/${action.payload}`);
    if (response === 'ERROR_OCCURED') {
        yield put(getSubscriptionFailed(response));
    } else {
        yield put(getSubscriptionSuccess(response));
    }
}

function* addToSubscription(action) {
    yield call(
        postActionOnAPI,
        `${apiUrl}/subscriptions/${action.payload.companyId}/apps/${action.payload.cartItem._id}`,
        {
            subscriptionType: action.payload.subscriptionType,
            cartItem: action.payload.cartItem,
        }
    );
}

function* removeModuleFromSubscription(action) {
    const response = yield call(
        deleteActionOnAPI,
        `${apiUrl}/subscriptions/${action.payload.companyId}/apps/${action.payload.appId}/modules/${action.payload.moduleId}`
    );
    if (response === 'ERROR_OCCURED') {
        yield put(removeModuleFromSubscriptionFailed('IT FAILED'));
    } else {
        yield put({ type: 'GET_SUBSCRIPTION', payload: action.payload.companyId });
    }
}

function* removeAppFromSubscription(action) {
    yield call(deleteActionOnAPI, `${apiUrl}/subscriptions/${action.payload.companyId}/apps/${action.payload.appId}`);
    yield put({ type: 'GET_SUBSCRIPTION', payload: action.payload.companyId });
}

function* updateSubscription(action) {
    yield call(putActionOnAPI, `${apiUrl}/subscriptions/${action.payload.subscription._id}`, {
        subscription: action.payload.subscription,
    });
    yield put({ type: 'GET_SUBSCRIPTION', payload: action.payload.subscription.company });
}

function* addAttachmentToSubscription(action) {
    yield call(
        postActionOnAPI,
        `${apiUrl}/subscriptions/${action.payload.company}/attachments`,
        action.payload.attachment
    );

    yield put({ type: 'ADD_ATTACHMENT_TO_SUBSCRIPTION_SUCCESS', payload: {} });
}

function* getSubscriptionsByAppId(action) {
    const response = yield call(getActionOnAPI, `${apiUrl}/subscriptions/apps?appOrModuleId=${action.payload}`);

    if (response === 'ERROR_OCCURED') {
        yield put(getSubscriptionFailed('IT FAILED'));
    } else {
        yield put(getSubscriptionsByAppIdSuccess(response));
    }
}

export function* watchGetSubscription() {
    yield takeEvery(GET_SUBSCRIPTION, getSubscription);
}

export function* watchAddToSubscription() {
    yield takeEvery(ADD_TO_SUBSCRIPTION, addToSubscription);
}
export function* watchRemoveModuleFromSubscription() {
    yield takeEvery(REMOVE_MODULE_FROM_SUBSCRIPTION, removeModuleFromSubscription);
}
export function* watchRemoveAppFromSubscription() {
    yield takeEvery(REMOVE_APP_FROM_SUBSCRIPTION, removeAppFromSubscription);
}

export function* watchUpdateSubscription() {
    yield takeEvery(UPDATE_SUBSCRIPTION, updateSubscription);
}

export function* watchAddAttachmentToSubscription() {
    yield takeEvery(ADD_ATTACHMENT_TO_SUBSCRIPTION, addAttachmentToSubscription);
}

export function* watchGetSubscriptionsByAppId() {
    yield takeEvery(GET_SUBSCRIPTIONS_BY_APP_ID, getSubscriptionsByAppId);
}

function* subscriptionSaga() {
    yield all([
        fork(watchGetSubscription),
        fork(watchAddToSubscription),
        fork(watchRemoveModuleFromSubscription),
        fork(watchRemoveAppFromSubscription),
        fork(watchUpdateSubscription),
        fork(watchAddAttachmentToSubscription),
        fork(watchGetSubscriptionsByAppId),
    ]);
}

export default subscriptionSaga;

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { getActionOnAPI, postActionOnAPI, putActionOnAPI, deleteActionOnAPI } from '../../helpers/backend';
import {
    GET_GEARS,
    GET_APPS,
    GET_APP,
    GET_INDUSTRIES,
    ADD_APP,
    UPDATE_APP,
    DELETE_APP,
    ADD_COMPANY,
    GET_COMPANIES,
    GET_COMPANY,
    UPDATE_COMPANY,
    GET_ROLES,
    GET_MODULES,
    GET_MODULES_BY_APP_ID,
    VERIFY_COMPANY_STATUS,
} from './constants';
import {
    getGearsSuccess,
    getGearsFailed,
    getAppsSuccess,
    getAppsFailed,
    getAppSuccess,
    getAppFailed,
    getApps,
    getIndustriesSuccess,
    getIndustriesFailed,
    addAppSuccess,
    addAppFailed,
    updateAppSuccess,
    updateAppFailed,
    deleteAppSuccess,
    deleteAppFailed,
    addCompanySuccess,
    addCompanyFailed,
    getCompaniesSuccess,
    getCompaniesFailed,
    getCompanies,
    getCompanySuccess,
    getCompanyFailed,
    updateCompanySuccess,
    updateCompanyFailed,
    getModulesSuccess,
    getModulesFailed,
    getModules,
    verifyCompanyStatusResult,
    
} from './actions';

import{updateUsersCompanyName} from '../user/actions'

const apiUrl = process.env.REACT_APP_AM_API_URL;

function* getGearsFromAPI() {
    const response = yield call(getActionOnAPI, `${apiUrl}/gears`);
    if (response === 'Unauthorized') {
        yield put(getGearsFailed(response));
    } else {
        yield put(getGearsSuccess(response));
    }
}

function* getIndustriesFromAPI() {
    const response = yield call(getActionOnAPI, `${apiUrl}/industries`);
    if (response === 'Unauthorized') {
        yield put(getIndustriesFailed(response));
    } else {
        yield put(getIndustriesSuccess(response));
    }
}

function* getAppsFromAPI() {
    const response = yield call(getActionOnAPI, `${apiUrl}/apps`);

    if (response === 'Unauthorized') {
        yield put(getAppsFailed(response));
    } else {
        yield put(getAppsSuccess(response));
    }
}

function* getAppFromAPI(action) {
    const response = yield call(getActionOnAPI, `${apiUrl}/apps/${action.payload}`);
    if (response === 'Unauthorized') {
        yield put(getAppFailed(response));
    } else {
        yield put(getAppSuccess(response));
    }
}

function* updateAppInAPI(action) {
    const response = yield call(putActionOnAPI, `${apiUrl}/apps/${action.payload.id}`, action.payload.app);
    if (response === 'Unauthorized') {
        yield put(updateAppFailed('updateAppErrorMessage'));
    } else {
        yield put(updateAppSuccess('updateAppSuccessMessage'));
        yield put(getModules());
        yield put(getApps());
    }
}

function* deleteAppFromAPI(action) {
    const response = yield call(deleteActionOnAPI, `${apiUrl}/apps/${action.payload}`);
    if (response === 'Unauthorized') {
        yield put(deleteAppFailed('deleteAppErrorMessage'));
    } else {
        yield put(deleteAppSuccess('deleteAppSuccessMessage'));
        yield put(getApps());
    }
}

function* postAppToAPI(action) {
    const response = yield call(postActionOnAPI, `${apiUrl}/apps`, action.payload);

    if (response === 'Unauthorized') {
        yield put(addAppFailed('addAppErrorMessage'));
    } else {
        yield put(addAppSuccess('The app was successfully added.'));
        yield put(getApps());
        yield put(getModules());
    }
}

function* postCompanyToAPI(action) {
    const response = yield call(postActionOnAPI, `${apiUrl}/companies`, action.payload);
    if (response === 'Unauthorized') {
        yield put(addCompanyFailed('addCompanyErrorMessage'));
    } else {
        yield put(addCompanySuccess('addCompanySuccessMessage'));
        yield put(getCompanies());
    }
}

function* getCompaniesFromAPI() {
    const response = yield call(getActionOnAPI, `${apiUrl}/companies`);
    if (response === 'Unauthorized') {
        yield put(getCompaniesFailed(response));
    } else {
        yield put(getCompaniesSuccess(response));
    }
}

function* getCompanyFromAPI(action) {
    const response = yield call(getActionOnAPI, `${apiUrl}/companies/${action.payload}`);
    if (response === 'Unauthorized') {
        yield put(getCompanyFailed(response));
    } else {
        yield put(getCompanySuccess(response));
    }
}

function* updateCompanyInAPI(action) {
    const response = yield call(putActionOnAPI, `${apiUrl}/companies/${action.payload.id}`, action.payload.company);
    if (response === 'Unauthorized') {
        yield put(updateCompanyFailed('updateCompanyErrorMessage'));
    } else {
        yield put(updateCompanySuccess('updateCompanySuccessMessage'));
        yield put(updateUsersCompanyName(action.payload.company))
        yield put(getCompanies());
    }
}

function* getModulesFromAPI() {
    const response = yield call(getActionOnAPI, `${apiUrl}/modules`);
    if (response === 'Unauthorized') {
        yield put(getModulesFailed(response));
    } else {
        yield put(getModulesSuccess(response));
    }
}

function* getModulesByAppId(action) {
    const response = yield call(getActionOnAPI, `${apiUrl}/modules/app?appId${action.payload}`);
    if (response === 'Unauthorized') {
        yield put(getModulesFailed(response));
    } else {
        yield put(getModulesSuccess(response));
    }
}

function* verifyCompanyStatusOnAPI(action) {
    const response = yield call(getActionOnAPI, `${apiUrl}/companies/${action.payload}`);
    if (response === 'Unauthorized') {
        yield put(verifyCompanyStatusResult(false));
    } else {
        if (response.status && response.status === 'deactivated') {
            yield put(verifyCompanyStatusResult(false));
        } else {
            yield put(verifyCompanyStatusResult(true));
        }
    }
}

export function* watchVerifyCompanyStatus() {
    yield takeEvery(VERIFY_COMPANY_STATUS, verifyCompanyStatusOnAPI);
}

export function* watchGetGearsFromAPI() {
    yield takeEvery(GET_GEARS, getGearsFromAPI);
}

export function* watchGetIndustriesFromAPI() {
    yield takeEvery(GET_INDUSTRIES, getIndustriesFromAPI);
}

export function* watchGetAppFromAPI() {
    yield takeEvery(GET_APP, getAppFromAPI);
}

export function* watchGetAppsFromAPI() {
    yield takeEvery(GET_APPS, getAppsFromAPI);
}

export function* watchAddApp() {
    yield takeEvery(ADD_APP, postAppToAPI);
}

export function* watchUpdateApp() {
    yield takeEvery(UPDATE_APP, updateAppInAPI);
}

export function* watchDeleteApp() {
    yield takeEvery(DELETE_APP, deleteAppFromAPI);
}

export function* watchAddCompany() {
    yield takeEvery(ADD_COMPANY, postCompanyToAPI);
}

export function* watchGetCompanies() {
    yield takeEvery(GET_COMPANIES, getCompaniesFromAPI);
}

export function* watchGetCompany() {
    yield takeEvery(GET_COMPANY, getCompanyFromAPI);
}

export function* watchUpdateCompany() {
    yield takeEvery(UPDATE_COMPANY, updateCompanyInAPI);
}

export function* watchGetModulesFromAPI() {
    yield takeEvery(GET_MODULES, getModulesFromAPI);
}

export function* watchGetModulesByAppId() {
    yield takeEvery(GET_MODULES_BY_APP_ID, getModulesByAppId);
}

function* adminSaga() {
    yield all([
        fork(watchGetGearsFromAPI),
        fork(watchGetAppsFromAPI),
        fork(watchGetAppFromAPI),
        fork(watchGetIndustriesFromAPI),
        fork(watchAddApp),
        fork(watchUpdateApp),
        fork(watchDeleteApp),
        fork(watchAddCompany),
        fork(watchGetCompanies),
        fork(watchGetCompany),
        fork(watchUpdateCompany),
        fork(watchGetModulesFromAPI),
        fork(watchGetModulesByAppId),
        fork(watchVerifyCompanyStatus),
    ]);
}

export default adminSaga;

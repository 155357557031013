import {
    GET_GEARS,
    GET_GEARS_SUCCESS,
    GET_GEARS_FAILED,
    GET_APPS,
    GET_APPS_SUCCESS,
    GET_APPS_FAILED,
    GET_APP,
    GET_APP_SUCCESS,
    GET_APP_FAILED,
    GET_INDUSTRIES,
    GET_INDUSTRIES_SUCCESS,
    GET_INDUSTRIES_FAILED,
    ADD_APP,
    ADD_APP_SUCCESS,
    ADD_APP_FAILED,
    UPDATE_APP,
    UPDATE_APP_SUCCESS,
    UPDATE_APP_FAILED,
    DELETE_APP,
    DELETE_APP_SUCCESS,
    DELETE_APP_FAILED,
    ADD_COMPANY,
    ADD_COMPANY_SUCCESS,
    ADD_COMPANY_FAILED,
    GET_COMPANY,
    GET_COMPANY_SUCCESS,
    GET_COMPANY_FAILED,
    GET_COMPANIES,
    GET_COMPANIES_SUCCESS,
    GET_COMPANIES_FAILED,
    UPDATE_COMPANY,
    UPDATE_COMPANY_SUCCESS,
    UPDATE_COMPANY_FAILED,
    CLEAR_MESSAGES,
    CLEAR_ERRORS,
    CLEAR_APP,
    GET_MODULES,
    GET_MODULES_SUCCESS,
    GET_MODULES_FAILED,
    GET_MODULES_BY_APP_ID,
    VERIFY_COMPANY_STATUS,
    VERIFY_COMPANY_STATUS_RESULT,
} from './constants';

/* GEARS */

export const getGears = () => ({
    type: GET_GEARS,
    payload: {},
});

export const getGearsSuccess = (gears) => ({
    type: GET_GEARS_SUCCESS,
    payload: gears,
});

export const getGearsFailed = (error) => ({
    type: GET_GEARS_FAILED,
    payload: error,
});

/* INDUSTRIES */

export const getIndustries = () => ({
    type: GET_INDUSTRIES,
    payload: {},
});

export const getIndustriesSuccess = (industries) => ({
    type: GET_INDUSTRIES_SUCCESS,
    payload: industries,
});

export const getIndustriesFailed = (error) => ({
    type: GET_INDUSTRIES_FAILED,
    payload: error,
});

/* APPS */

export const getApps = () => ({
    type: GET_APPS,
    payload: {},
});

export const getAppsSuccess = (apps) => ({
    type: GET_APPS_SUCCESS,
    payload: apps,
});

export const getAppsFailed = (error) => ({
    type: GET_APPS_FAILED,
    payload: error,
});

export const getApp = (id) => ({
    type: GET_APP,
    payload: id,
});

export const getAppSuccess = (app) => ({
    type: GET_APP_SUCCESS,
    payload: app,
});

export const getAppFailed = (error) => ({
    type: GET_APP_FAILED,
    payload: error,
});

export const addApp = (app) => ({
    type: ADD_APP,
    payload: app,
});

export const addAppSuccess = (message) => ({
    type: ADD_APP_SUCCESS,
    payload: message,
});

export const addAppFailed = (error) => ({
    type: ADD_APP_FAILED,
    payload: error,
});

export const updateApp = (id, app) => ({
    type: UPDATE_APP,
    payload: {
        id: id,
        app: app,
    },
});

export const updateAppSuccess = (message) => ({
    type: UPDATE_APP_SUCCESS,
    payload: message,
});

export const updateAppFailed = (error) => ({
    type: UPDATE_APP_FAILED,
    payload: error,
});

export const deleteApp = (id) => ({
    type: DELETE_APP,
    payload: id,
});

export const deleteAppSuccess = (message) => ({
    type: DELETE_APP_SUCCESS,
    payload: message,
});

export const deleteAppFailed = (error) => ({
    type: DELETE_APP_FAILED,
    payload: error,
});

export const clearMessages = () => ({
    type: CLEAR_MESSAGES,
    payload: '',
});

export const clearErrors = () => ({
    type: CLEAR_ERRORS,
    payload: '',
});

export const clearApp = () => ({
    type: CLEAR_APP,
    payload: [],
});

/* MODULES */

export const getModules = () => ({
    type: GET_MODULES,
    payload: {},
});

export const getModulesSuccess = (modules) => ({
    type: GET_MODULES_SUCCESS,
    payload: modules,
});

export const getModulesFailed = (error) => ({
    type: GET_MODULES_FAILED,
    payload: error,
});

/* COMPANIES*/
export const addCompany = (company) => ({
    type: ADD_COMPANY,
    payload: company,
});

export const addCompanySuccess = (message) => ({
    type: ADD_COMPANY_SUCCESS,
    payload: message,
});

export const addCompanyFailed = (error) => ({
    type: ADD_COMPANY_FAILED,
    payload: error,
});

export const getCompanies = () => ({
    type: GET_COMPANIES,
    payload: {},
});

export const getCompaniesSuccess = (companies) => ({
    type: GET_COMPANIES_SUCCESS,
    payload: companies,
});

export const getCompaniesFailed = (error) => ({
    type: GET_COMPANIES_FAILED,
    payload: error,
});

export const getCompany = (id) => ({
    type: GET_COMPANY,
    payload: id,
});

export const getCompanySuccess = (company) => ({
    type: GET_COMPANY_SUCCESS,
    payload: company,
});

export const getCompanyFailed = (error) => ({
    type: GET_COMPANY_FAILED,
    payload: error,
});

export const updateCompany = (id, company) => ({
    type: UPDATE_COMPANY,
    payload: {
        id: id,
        company: company,
    },
});

export const updateCompanySuccess = (message) => ({
    type: UPDATE_COMPANY_SUCCESS,
    payload: message,
});

export const updateCompanyFailed = (error) => ({
    type: UPDATE_COMPANY_FAILED,
    payload: error,
});

export const getModulesByAppId = (appId) => ({
    type: GET_MODULES_BY_APP_ID,
    payload: appId,
});

export const verifyCompanyStatus = (companyId) => ({
    type: VERIFY_COMPANY_STATUS,
    payload: companyId,
});

export const verifyCompanyStatusResult = (isCompanyActive) => ({
    type: VERIFY_COMPANY_STATUS_RESULT,
    payload: isCompanyActive,
});

import {
    UPDATE_PUBLICITY,
    DELETE_PUBLICITY,
    DELETE_PUBLICITY_SUCCESS,
    DELETE_PUBLICITY_FAILED,
    ADD_PUBLICITY,
    ADD_PUBLICITY_SUCCESS,
    ADD_PUBLICITY_FAILED,
    GET_ADVERTISEMENTS,
    GET_ADVERTISEMENTS_SUCCESS,
    GET_ADVERTISEMENTS_FAILED
} from './constants';

const INIT_STATE = {
    advertisements: [],
    publicity : {},
    message : '',
    error : '',
    loading : false
};

const Marketing = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ADVERTISEMENTS:
            return { ...state, advertisements: action.payload, loading: true };
        case GET_ADVERTISEMENTS_SUCCESS:
            return { ...state, advertisements: action.payload, loading: false };
        case GET_ADVERTISEMENTS_FAILED:
            return { ...state, error: action.payload, loading: false };
        case ADD_PUBLICITY:
            return { ...state, publicity: action.payload, loading: true }
        case ADD_PUBLICITY_SUCCESS:
            return { ...state, message: action.payload, loading: false };
        case ADD_PUBLICITY_FAILED:
            return { ...state, error: action.payload, loading: false };
        case DELETE_PUBLICITY:
            return { ...state, publicity: action.payload, loading: false};
        case DELETE_PUBLICITY_SUCCESS:
            return { ...state, message: action.payload, loading: false};
        case DELETE_PUBLICITY_FAILED:
            return { ...state, error: action.payload, loading: false };
        case UPDATE_PUBLICITY:
            return { ...state, publicity: action.payload,loading: false};
        default:
            return { ...state };
    }
};

export default Marketing;

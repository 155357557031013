import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';


i18n
    .use(LanguageDetector)
    .use(Backend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        keySeparator: false, // we do not use keys in form messages.welcome
        detection: {
            order: ['cookie','navigator'],
            caches: ['cookie'],
            cookieMinutes: 160,
            lookupQuerystring: 'lang',
            lookupFromPathIndex: 0
        },
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });


export default i18n;
// @flow
import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router'

import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import sagas from './sagas';

import { sessionCheck } from './user/actions';

export const history = createBrowserHistory()

const sagaMiddleware = createSagaMiddleware();


export default function configureStore(initialState: {}) {
    const middlewares = [sagaMiddleware, routerMiddleware(history)];
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store = createStore(
        reducers(history),
        initialState,
        composeEnhancers(applyMiddleware(...middlewares))
    );
    sagaMiddleware.run(sagas);

    store.dispatch(sessionCheck());

    return store;
}

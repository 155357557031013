export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_APP_FROM_CART = "REMOVE_APP_FROM_CART"
export const REMOVE_MODULE_FROM_CART = "REMOVE_MODULE_FROM_CART"
export const REMOVE_APPS_FROM_CART = "REMOVE_APPS_FROM_CART"
export const GET_CART = "GET_CART"
export const GET_CART_SUCCESS = "GET_CART_SUCCESS"
export const GET_CART_FAILED = "GET_CART_FAILED"
export const UPDATE_CART_ITEM = "UPDATE_CART_ITEM"
export const UPDATE_CART = "UPDATE_CART"
export const GET_CARTS_BY_APP_ID = "GET_CARTS_BY_APP_ID"
export const GET_CARTS_BY_APP_ID_SUCCESS = "GET_CARTS_BY_APP_ID_SUCCESS"


export const UPDATE_PUBLICITY = "UPDATE_PUBLICITY"
export const UPDATE_PUBLICITY_SUCCESS = "UPDATE_PUBLICITY_SUCCESS"
export const UPDATE_PUBLICITY_FAILED = "UPDATE_PUBLICITY_FAILED"

export const GET_PUBLICITY_BY_ID = "GET_PUBLICITY_BY_ID"
export const GET_PUBLICITY_BY_ID_SUCCESS = "GET_PUBLICITY_BY_ID_SUCCESS"
export const GET_PUBLICITY_BY_ID_FAILED = "GET_PUBLICITY_BY_ID_FAILED"

export const DELETE_PUBLICITY = "DELETE_PUBLICITY"
export const DELETE_PUBLICITY_SUCCESS = "DELETE_PUBLICITY_SUCCESS"
export const DELETE_PUBLICITY_FAILED = "DELETE_PUBLICITY_FAILED"

export const ADD_PUBLICITY = "ADD_PUBLICITY"
export const ADD_PUBLICITY_SUCCESS = "ADD_PUBLICITY_SUCCESS"
export const ADD_PUBLICITY_FAILED = "ADD_PUBLICITY_FAILED"

export const GET_ADVERTISEMENTS = "GET_ADVERTISEMENTS"
export const GET_ADVERTISEMENTS_SUCCESS = "GET_ADVERTISEMENTS_SUCCESS"
export const GET_ADVERTISEMENTS_FAILED = "GET_ADVERTISEMENTS_FAILED"
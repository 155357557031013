import {
    GET_SUBSCRIPTION,
    GET_SUBSCRIPTION_SUCCESS,
    GET_SUBSCRIPTION_FAILED,
    ADD_TO_SUBSCRIPTION,
    REMOVE_MODULE_FROM_SUBSCRIPTION,
    REMOVE_MODULE_FROM_SUBSCRIPTION_SUCCESS,
    REMOVE_MODULE_FROM_SUBSCRIPTION_FAILED,
    REMOVE_APP_FROM_SUBSCRIPTION,
    UPDATE_SUBSCRIPTION,
    ADD_ATTACHMENT_TO_SUBSCRIPTION,
    GET_SUBSCRIPTIONS_BY_APP_ID,
    GET_SUBSCRIPTIONS_BY_APP_ID_SUCCESS,
} from './constants';

export const getSubscription = (company) => ({
    type: GET_SUBSCRIPTION,
    payload: company,
});

export const getSubscriptionSuccess = (subscription) => ({
    type: GET_SUBSCRIPTION_SUCCESS,
    payload: subscription,
});

export const getSubscriptionsByAppIdSuccess = (subscriptions) => ({
    type: GET_SUBSCRIPTIONS_BY_APP_ID_SUCCESS,
    payload: subscriptions,
});

export const getSubscriptionFailed = (error) => ({
    type: GET_SUBSCRIPTION_FAILED,
    payload: error,
});

export const addToSubscription = (companyId, subscriptionType, cartItem) => ({
    type: ADD_TO_SUBSCRIPTION,
    payload: {
        companyId: companyId,
        subscriptionType: subscriptionType,
        cartItem: cartItem,
    },
});

export const removeModuleFromSubscription = (companyId, appId, moduleId) => ({
    type: REMOVE_MODULE_FROM_SUBSCRIPTION,
    payload: {
        companyId: companyId,
        appId: appId,
        moduleId: moduleId,
    },
});

export const removeModuleFromSubscriptionSuccess = (message) => ({
    type: REMOVE_MODULE_FROM_SUBSCRIPTION_SUCCESS,
    payload: message,
});

export const removeModuleFromSubscriptionFailed = (error) => ({
    type: REMOVE_MODULE_FROM_SUBSCRIPTION_FAILED,
    payload: error,
});

export const removeAppFromSubscription = (companyId, appId) => ({
    type: REMOVE_APP_FROM_SUBSCRIPTION,
    payload: {
        companyId: companyId,
        appId: appId,
    },
});

export const updateSubscription = (subscription) => ({
    type: UPDATE_SUBSCRIPTION,
    payload: {
        subscription: subscription,
    },
});

export const addAttachmentToSubscription = (companyId, attachment) => ({
    type: ADD_ATTACHMENT_TO_SUBSCRIPTION,
    payload: {
        company: companyId,
        attachment: attachment,
    },
});

export const addAttachmentToSubscriptionSuccess = () => ({
    type: ADD_ATTACHMENT_TO_SUBSCRIPTION,
});

export const getSubscriptionsByAppId = (appId) => ({
    type: GET_SUBSCRIPTIONS_BY_APP_ID,
    payload:appId
});


// @flow
import { all } from 'redux-saga/effects';
import layoutSaga from './layout/saga';
import appMenuSaga from './appMenu/saga';
import userSaga from './user/saga';
import adminSaga from './admin/saga';
import cartSaga from './cart/saga';
import subscriptionSaga from './subscription/saga';
import diagnosticSaga from './diagnostic/saga';
import marketingSaga from './marketing/saga';

export default function* rootSaga(getState: any): any {
    yield all([adminSaga(), layoutSaga(), appMenuSaga(), userSaga(), cartSaga(), subscriptionSaga(), diagnosticSaga(), marketingSaga()]);
}
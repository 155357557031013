import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { getActionOnAPI, putActionOnAPI, postActionOnAPI, deleteActionOnAPI } from '../../helpers/backend';
import {
    GET_DIAGNOSTIC,
    ADD_STEP_LISTING,
    REMOVE_STEP_LISTING,
    UPDATE_STEP_LISTING,
    ADD_STEP,
    REMOVE_STEP,
    GET_STEP_LISTING,
    ADD_APPS_TO_STEP,
    UPDATE_STEP,
    MODIFY_STEP_SEQUENCE,
    GET_STEPS,
    GET_STEP,
    REMOVE_MODULE_FROM_STEP,
    GET_DIAGNOSTICS_BY_APP_ID,
    DELETE_MODULE_FROM_ALL_STEPS,
} from './constants';
import {
    getDiagnosticSuccess,
    getDiagnosticFailed,
    addStepListingSuccess,
    addStepListingFailed,
    removeStepListingSuccess,
    removeStepListingFailed,
    updateStepListingSuccess,
    updateStepListingFailed,
    addStepSuccess,
    addStepFailed,
    updateStepSuccess,
    updateStepFailed,
    removeStepSuccess,
    removeStepFailed,
    getStepListingSuccess,
    getStepListingFailed,
    getStepsSuccess,
    getStepsFailed,
    getStepSuccess,
    getStepFailed,
    addAppsToStepSuccess,
    addAppsToStepFailed,
    removeModuleFromStepSuccess,
    removeModuleFromStepFailed,
    getDiagnosticsByAppIdSuccess,
} from './actions';

import {deleteApp} from '../admin/actions';


const apiUrl = process.env.REACT_APP_AM_API_URL;

function* getDiagnostic(action) {
    const response = yield call(getActionOnAPI, `${apiUrl}/diagnostics/${action.payload}`);
    if (response === 'ERROR_OCCURED') {
        yield put(getDiagnosticFailed(response));
    } else {
        yield put(getDiagnosticSuccess(response));
    }
}

function* addStepListing(action) {
    const response = yield call(
        postActionOnAPI,
        `${apiUrl}/diagnostics/${action.payload.company}/stepListings`,
        action.payload.stepListing
    );

    if (response === 'ERROR_OCCURED') {
        yield put(addStepListingFailed('IT FAILED'));
    } else {
        yield put(addStepListingSuccess('IT WORKED'));
    }
    yield put({ type: 'GET_DIAGNOSTIC', payload: action.payload.company });
}

function* removeStepListing(action) {
    const response = yield call(
        deleteActionOnAPI,
        `${apiUrl}/diagnostics/${action.payload.company}/stepListings/${action.payload.stepListingId}`
    );
    if (response === 'ERROR_OCCURED') {
        yield put(removeStepListingFailed('IT FAILED'));
    } else {
        yield put(removeStepListingSuccess('IT WORKED'));
    }
    yield put({ type: 'GET_DIAGNOSTIC', payload: action.payload.company });
}

function* updateStepListing(action) {
    const response = yield call(
        putActionOnAPI,
        `${apiUrl}/diagnostics/${action.payload.company}/stepListings/${action.payload.stepListingId}`,
        action.payload.stepListing
    );
    if (response === 'ERROR_OCCURED') {
        yield put(updateStepListingFailed('IT FAILED'));
    } else {
        yield put(updateStepListingSuccess('IT WORKED'));
    }
    yield put({ type: 'GET_DIAGNOSTIC', payload: action.payload.company });
}

function* addStep(action) {
    const response = yield call(
        postActionOnAPI,
        `${apiUrl}/diagnostics/${action.payload.companyId}/stepListings/${action.payload.stepListingId}`,
        action.payload.step
    );

    if (response === 'ERROR_OCCURED') {
        yield put(addStepFailed('IT FAILED'));
    } else {
        yield put(addStepSuccess('IT WORKED'));
    }
    yield put({
        type: 'GET_STEP_LISTING',
        payload: { companyId: action.payload.companyId, stepListingId: action.payload.stepListingId },
    });
}

function* updateStep(action) {
    const response = yield call(
        putActionOnAPI,
        `${apiUrl}/diagnostics/${action.payload.companyId}/stepListings/${action.payload.stepListingId}/steps/${action.payload.stepId}`,
        action.payload.step
    );

    if (response === 'ERROR_OCCURED') {
        yield put(updateStepFailed('IT FAILED'));
    } else {
        yield put(updateStepSuccess('IT WORKED'));
    }
    yield put({
        type: 'GET_STEP_LISTING',
        payload: { companyId: action.payload.companyId, stepListingId: action.payload.stepListingId },
    });
}

function* removeStep(action) {
    const response = yield call(
        deleteActionOnAPI,
        `${apiUrl}/diagnostics/${action.payload.companyId}/stepListings/${action.payload.stepListingId}/steps/${action.payload.stepId}`
    );
    if (response === 'ERROR_OCCURED') {
        yield put(removeStepFailed('IT FAILED'));
    } else {
        yield put(removeStepSuccess('IT WORKED'));
    }
    yield put({
        type: 'GET_STEP_LISTING',
        payload: { companyId: action.payload.companyId, stepListingId: action.payload.stepListingId },
    });
}

function* getStepListing(action) {
    const response = yield call(
        getActionOnAPI,
        `${apiUrl}/diagnostics/${action.payload.companyId}/stepListings/${action.payload.stepListingId}`
    );
    if (response === 'ERROR_OCCURED') {
        yield put(getStepListingFailed('Error'));
    } else {
        yield put(getStepListingSuccess(response));
        yield put({ type: 'MODIFY_STEP_SUCCESS', payload: true });
    }
}

function* getSteps(action) {
    const response = yield call(
        getActionOnAPI,
        `${apiUrl}/diagnostics/${action.payload.companyId}/stepListings/${action.payload.stepListingId}/steps`
    );
    if (response === 'ERROR_OCCURED') {
        yield put(getStepsFailed('Error'));
    } else {
        yield put(getStepsSuccess(response));
    }
}

function* getStep(action) {
    const response = yield call(
        getActionOnAPI,
        `${apiUrl}/diagnostics/${action.payload.companyId}/stepListings/${action.payload.stepListingId}/steps/${action.payload.stepId}`
    );
    if (response === 'ERROR_OCCURED') {
        yield put(getStepFailed('Error'));
    } else {
        yield put(getStepSuccess(response));
    }
}

function* addAppsToStep(action) {
    const response = yield call(
        postActionOnAPI,
        `${apiUrl}/diagnostics/${action.payload.companyId}/stepListings/${action.payload.stepListingId}/steps/${action.payload.stepId}`,
        { apps: action.payload.apps, modules: action.payload.modules }
    );
    if (response === 'ERROR_OCCURED') {
        yield put(addAppsToStepFailed('IT FAILED'));
    } else {
        yield put(addAppsToStepSuccess('IT WORKED'));
    }
    yield put({
        type: 'GET_STEP_LISTING',
        payload: { companyId: action.payload.companyId, stepListingId: action.payload.stepListingId },
    });
}

function* removeModuleFromStep(action) {
    const response = yield call(
        deleteActionOnAPI,
        `${apiUrl}/diagnostics/${action.payload.companyId}/stepListings/${action.payload.stepListingId}/steps/${action.payload.stepId}/modules/${action.payload.moduleId}`
    );
    if (response === 'ERROR_OCCURED') {
        yield put(removeModuleFromStepFailed('IT FAILED'));
    } else {
        yield put(removeModuleFromStepSuccess('IT WORKED'));
    }
    yield put({
        type: 'GET_STEP_LISTING',
        payload: { companyId: action.payload.companyId, stepListingId: action.payload.stepListingId },
    });
}

function* deleteModuleFromAllSteps(action) {

    const response = yield call(
        deleteActionOnAPI,
        `${apiUrl}/diagnostics/stepListings/steps/apps/${action.payload}`
    );
    if (response === 'ERROR_OCCURED') {
        yield put(removeModuleFromStepFailed('IT FAILED'));
    } else {
        yield put(deleteApp(action.payload));
    }
}

function* modifyStepSequence(action) {
    yield call(
        putActionOnAPI,
        `${apiUrl}/diagnostics/${action.payload.companyId}/stepListings/${action.payload.stepListingId}/steps/${action.payload.stepId}/sequence`,
        { sequenceModifier: action.payload.sequenceModifier }
    );
    yield put({
        type: 'GET_STEP_LISTING',
        payload: { companyId: action.payload.companyId, stepListingId: action.payload.stepListingId },
    });
}

function* getDiagnosticsByAppId(action) {
    const response = yield call(getActionOnAPI, `${apiUrl}/diagnostics/stepListings/steps/apps/${action.payload}`);
    if (response === 'ERROR_OCCURED') {
        //yield put(getStepsByAppIdFailed('IT FAILED'));
    } else {
        yield put(getDiagnosticsByAppIdSuccess(response));
    }
}

export function* watchGetDiagnostic() {
    yield takeEvery(GET_DIAGNOSTIC, getDiagnostic);
}

export function* watchAddStepListing() {
    yield takeEvery(ADD_STEP_LISTING, addStepListing);
}

export function* watchRemoveStepListing() {
    yield takeEvery(REMOVE_STEP_LISTING, removeStepListing);
}

export function* watchUpdateStepListing() {
    yield takeEvery(UPDATE_STEP_LISTING, updateStepListing);
}

export function* watchAddStep() {
    yield takeEvery(ADD_STEP, addStep);
}

export function* watchUpdateStep() {
    yield takeEvery(UPDATE_STEP, updateStep);
}

export function* watchRemoveStep() {
    yield takeEvery(REMOVE_STEP, removeStep);
}

export function* watchGetStepListing() {
    yield takeEvery(GET_STEP_LISTING, getStepListing);
}

export function* watchGetSteps() {
    yield takeEvery(GET_STEPS, getSteps);
}

export function* watchGetStep() {
    yield takeEvery(GET_STEP, getStep);
}

export function* watchAddAppsToStep() {
    yield takeEvery(ADD_APPS_TO_STEP, addAppsToStep);
}

export function* watchRemoveModuleFromStep() {
    yield takeEvery(REMOVE_MODULE_FROM_STEP, removeModuleFromStep);
}

export function* watchModifyStepSequence() {
    yield takeEvery(MODIFY_STEP_SEQUENCE, modifyStepSequence);
}

export function* watchGetDiagnosticsByAppId() {
    yield takeEvery(GET_DIAGNOSTICS_BY_APP_ID, getDiagnosticsByAppId);
}

export function* watchDeleteModuleFromAllSteps() {
    yield takeEvery(DELETE_MODULE_FROM_ALL_STEPS, deleteModuleFromAllSteps);
}


function* diagnosticSaga() {
    yield all([
        fork(watchGetDiagnostic),
        fork(watchAddStepListing),
        fork(watchRemoveStepListing),
        fork(watchUpdateStepListing),
        fork(watchAddStep),
        fork(watchUpdateStep),
        fork(watchRemoveStep),
        fork(watchGetStepListing),
        fork(watchAddAppsToStep),
        fork(watchModifyStepSequence),
        fork(watchGetSteps),
        fork(watchGetStep),
        fork(watchRemoveModuleFromStep),
        fork(watchGetDiagnosticsByAppId),
        fork(watchDeleteModuleFromAllSteps),
    ]);
}

export default diagnosticSaga;

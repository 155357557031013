import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { getActionOnAPI, putActionOnAPI, postActionOnAPI, deleteActionOnAPI } from '../../helpers/backend';
import {
    GET_ADVERTISEMENTS,
    ADD_PUBLICITY,
    DELETE_PUBLICITY,
    UPDATE_PUBLICITY,
} from './constants';
import {
    getAdvertisements,
    getAdvertisementsFailed,
    getAdvertisementsSuccess,
    addPublicityFailed,
    addPublicitySuccess,
    deletePublicityFailed,
    deletePublicitySuccess,
    updatePublicityFailed,
    updatePublicitySuccess,
} from './actions';




const apiUrl = process.env.REACT_APP_AM_API_URL;

function* getPublicity() {
    const response = yield call(getActionOnAPI, `${apiUrl}/know_more?App=All`);
    if (response === 'ERROR_OCCURED') {
        yield put(getAdvertisementsFailed(response));
    } else {
        yield put(getAdvertisementsSuccess(response));
    }
}

function* addPublicity(action){
    const response = yield call(postActionOnAPI, `${apiUrl}/advertisements`, action.payload);
    if (response === 'Unauthorized') {
        yield put(addPublicityFailed('Unable to add'));
    } else {
        yield put(addPublicitySuccess('added successfully'));
        yield put(getAdvertisements());
    }
}

function* deletePublicity(action){
    const response = yield call(deleteActionOnAPI, `${apiUrl}/advertisements/${action.payload._id}`);
    if(response === 'ERROR_OCCURED'){
        yield put(deletePublicityFailed('FAILED'));
    }else {
        yield put(deletePublicitySuccess('PASSED'));
        yield put(getAdvertisements());
    }
}

function* updatePublicity(action){
    const response = yield call(putActionOnAPI, `${apiUrl}/advertisement/${action.payload._id}`, action.payload);
    if(response === 'ERROR_OCCURED'){
        yield put(updatePublicityFailed('FAILED'));
    }else {
        yield put(updatePublicitySuccess('PASSED'));
        yield put(getAdvertisements());
    }
}

export function* watchGetPublicity() {
    yield takeEvery(GET_ADVERTISEMENTS, getPublicity);

}
export function* watchAddPublicity() {
    yield takeEvery(ADD_PUBLICITY, addPublicity);
}

export function* watchDeletePublicity() {
    yield takeEvery(DELETE_PUBLICITY, deletePublicity);
}

export function* watchUpdatePublicity() {
    yield takeEvery(UPDATE_PUBLICITY,updatePublicity);
}

function* diagnosticSaga() {
    yield all([
        fork(watchGetPublicity),
        fork(watchAddPublicity),
        fork(watchDeletePublicity),
        fork(watchUpdatePublicity)
    ]);
}

export default diagnosticSaga;
